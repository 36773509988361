import {
    Container,
    Box,
    Snackbar,
    Typography,
    Stack,
    Button,
    CircularProgress,
} from '@mui/material';
import React, { ReactElement, useState, useEffect } from 'react';
import { OrganizationPublic } from '../../../utils';
import getOrganizationBySlug from '../../../api/Public/getOrganizationBySlug';
import { useHistory } from 'react-router-dom';
import getCurrentUser from '../../../api/Auth/getCurrentUser';

const AthleteStart = (): ReactElement => {
    const [organization, setOrganization] = useState<OrganizationPublic | null>(null);
    const [snackMessage, setSnackMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const history = useHistory();

    useEffect(() => {
        const getOrganizationFromUrlSlug = async () => {
            try {
                setIsLoading(true);
                const response = await getOrganizationBySlug(
                    window.location.pathname.split('/')[2],
                );
                console.log(response.data);
                setOrganization(response.data);

                try {
                    const userData = await getCurrentUser();
                    if (userData.data) {
                        // If user is already logged in, redirect to the athlete-subscribe page
                        history.push(`/athletes-subscribe/${response.data.slug}`);
                    }
                } catch (e) {
                    console.log('No user is currently logged in.');
                }
            } catch (e) {
                console.error('ERROR', e);
                setSnackMessage(
                    'Error retrieving organization information. Please check with your coach.',
                );
            } finally {
                setIsLoading(false);
            }
        };

        getOrganizationFromUrlSlug();
    }, [history]);

    const handleButtonClick = (path: string) => {
        const baseUrl = process.env.REACT_APP_URL || 'https://1tul.com';

        /**
         * This is the URL for the athlete to get a combo subscription upon login/registration.
         */
        const athleteSubscribeUrl = `${baseUrl}/athletes-subscribe/${organization?.slug}`;
        localStorage.setItem('athleteSubscribeUrl', athleteSubscribeUrl);
        history.push(path);
    };

    return (
        <main>
            <Snackbar
                open={snackMessage !== ''}
                autoHideDuration={6000}
                onClose={() => setSnackMessage('')}
            >
                <Stack
                    sx={{ width: '100%' }}
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography>{snackMessage}</Typography>
                </Stack>
            </Snackbar>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: 8,
                    pb: 6,
                }}
            >
                <Typography
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '1.5rem',
                    }}
                >
                    {organization?.name} account setup
                </Typography>
                <Box
                    sx={{
                        width: 150,
                        height: 150,
                        borderRadius: '25%',
                        border: '2px solid',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'hidden',
                        mb: 2,
                    }}
                >
                    <img
                        src={organization?.logo_url || ''}
                        alt={`${organization?.name} logo`}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                </Box>
            </Box>
            {!isLoading && organization ? (
                <Container>
                    <Typography variant="h6" align="center" gutterBottom>
                        Do you have a 1Tul account?
                    </Typography>
                    <Stack direction="row" spacing={2} justifyContent="center">
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => handleButtonClick('/login')}
                            style={{ marginRight: 10, width: '100px' }}
                        >
                            YES
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={() => handleButtonClick('/register')}
                            style={{ width: '100px' }}
                        >
                            NO
                        </Button>
                    </Stack>
                </Container>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress />
                </Box>
            )}
        </main>
    );
};

export default AthleteStart;
