import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    IconButton,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Autocomplete,
    FormHelperText,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { getPhysicalProducts } from '../../physicalProducts/physicalProducts.api';
import { PhysicalProduct } from '../../physicalProducts/physicalProducts';
import { Organization, UserData } from 'utils';
import { adminGetAllOrganizations } from '../../../admin.organizations.api';
import { adminGetAllUsers } from '../../../admin.users.api';
import { debounce } from 'lodash';
import { CreateOrderParams, OrderLineItem } from '../orders.api';
import statesAndProvinces from '../../../../../utils/geography';

interface OrderCreateDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (orderData: CreateOrderParams) => void;
}

const OrderCreateDialog: React.FC<OrderCreateDialogProps> = ({ open, onClose, onSubmit }) => {
    const [physicalProducts, setPhysicalProducts] = useState<PhysicalProduct[]>([]);
    const [lineItems, setLineItems] = useState<OrderLineItem[]>([
        {
            physical_product_uuid: '',
            quantity: 1,
            amount: 0,
            tax: 0,
        },
    ]);

    const [sellerOrganizations, setSellerOrganizations] = useState<Organization[]>([]);
    const [buyerOrganizations, setBuyerOrganizations] = useState<Organization[]>([]);
    const [buyerAthletes, setBuyerAthletes] = useState<UserData[]>([]);

    const [selectedSellerOrg, setSelectedSellerOrg] = useState<Organization | null>(null);
    const [selectedBuyerOrg, setSelectedBuyerOrg] = useState<Organization | null>(null);
    const [selectedAthlete, setSelectedAthlete] = useState<UserData | null>(null);

    const [notes, setNotes] = useState('');
    const [shippingAddress1, setShippingAddress1] = useState('');
    const [shippingAddress2, setShippingAddress2] = useState('');
    const [shippingCity, setShippingCity] = useState('');
    const [shippingState, setShippingState] = useState('');
    const [shippingZip, setShippingZip] = useState('');
    const [orderType, setOrderType] = useState<'comp' | 'replacement'>('replacement');
    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

    const fetchBuyerOrganizations = debounce((searchTerm: string) => {
        adminGetAllOrganizations(searchTerm, 500)
            .then((response) => setBuyerOrganizations(response.data.data))
            .catch((error) => console.error('Failed to fetch buyer organizations', error));
    }, 300);

    const fetchSellerOrganizations = debounce((searchTerm: string) => {
        adminGetAllOrganizations(searchTerm, 500)
            .then((response) => setSellerOrganizations(response.data.data))
            .catch((error) => console.error('Failed to fetch seller organizations', error));
    }, 300);

    const fetchBuyerAthletes = debounce((searchTerm: string) => {
        adminGetAllUsers(searchTerm, 500)
            .then((response) => setBuyerAthletes(response.data.data))
            .catch((error) => console.error('Failed to fetch athletes', error));
    }, 300);

    useEffect(() => {
        if (open) {
            getPhysicalProducts({ is_active: true })
                .then((response) => {
                    const availableProducts = response.data.data.filter(
                        (product) => product.inventory_count > 0,
                    );
                    setPhysicalProducts(availableProducts);
                })
                .catch((error) => console.error('Failed to fetch physical products:', error));

            fetchSellerOrganizations('');
            fetchBuyerOrganizations('');
            fetchBuyerAthletes('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleAddLineItem = () => {
        setLineItems([
            ...lineItems,
            {
                physical_product_uuid: '',
                quantity: 1,
                amount: 0,
                tax: 0,
            },
        ]);
    };

    const handleRemoveLineItem = (index: number) => {
        setLineItems(lineItems.filter((_, i) => i !== index));
    };

    const handleLineItemChange = (
        index: number,
        field: keyof OrderLineItem,
        value: string | number,
    ) => {
        const newLineItems = [...lineItems];
        newLineItems[index] = {
            ...newLineItems[index],
            [field]: value,
        };
        setLineItems(newLineItems);
    };

    const handleSubmit = () => {
        const errors: { [key: string]: string } = {};

        if (!selectedSellerOrg) {
            errors.sellerOrg = 'Seller organization is required';
        }

        if (!selectedBuyerOrg && !selectedAthlete) {
            errors.buyer = 'Either buyer organization or athlete must be selected';
        }

        if (!shippingAddress1) {
            errors.shippingAddress1 = 'Shipping address is required';
        }

        if (!shippingCity) {
            errors.shippingCity = 'City is required';
        }

        if (!shippingState) {
            errors.shippingState = 'State is required';
        }

        if (!shippingZip) {
            errors.shippingZip = 'ZIP code is required';
        }

        if (lineItems.length === 0 || lineItems.some((item) => !item.physical_product_uuid)) {
            errors.lineItems = 'At least one product must be selected';
        }

        setFormErrors(errors);

        if (Object.keys(errors).length > 0) {
            return;
        }

        const orderData: CreateOrderParams = {
            seller_org_uuid: selectedSellerOrg!.uuid,
            buyer_org_uuid: selectedBuyerOrg?.uuid,
            athlete_uuid: selectedAthlete?.uuid,
            tax: 0,
            shipping: 0,
            shipping_address_1: shippingAddress1,
            shipping_address_2: shippingAddress2,
            shipping_city: shippingCity,
            shipping_state: shippingState,
            shipping_zip: shippingZip,
            order_type: orderType,
            product_line_items: lineItems,
            order_notes: notes,
        };

        onSubmit(orderData);
        handleClose();
    };

    const handleClose = () => {
        setLineItems([
            {
                physical_product_uuid: '',
                quantity: 1,
                amount: 0,
                tax: 0,
            },
        ]);
        setSelectedSellerOrg(null);
        setSelectedBuyerOrg(null);
        setSelectedAthlete(null);
        setNotes('');
        setShippingAddress1('');
        setShippingAddress2('');
        setShippingCity('');
        setShippingState('');
        setShippingZip('');
        setFormErrors({});
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>Create Replacement or Comp Order</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 2,
                            mb: 2,
                            marginTop: '4px',
                        }}
                    >
                        <FormControl sx={{ width: '33%' }} error={!!formErrors.sellerOrg}>
                            <Autocomplete
                                value={
                                    sellerOrganizations.find(
                                        (org) => org.uuid === selectedSellerOrg?.uuid,
                                    ) || null
                                }
                                onChange={(event, newValue) => {
                                    setSelectedSellerOrg(newValue);
                                }}
                                onInputChange={(event, newInputValue, reason) => {
                                    if (reason !== 'reset') {
                                        fetchSellerOrganizations(newInputValue);
                                    }
                                }}
                                getOptionKey={(option) => option.uuid}
                                filterOptions={(options) => options}
                                size={'small'}
                                options={sellerOrganizations}
                                getOptionLabel={(option) =>
                                    `${option.name} (${option.subscription_tier?.name})  - ${option.uuid}`
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Seller Organization *"
                                        error={!!formErrors.sellerOrg}
                                        helperText={formErrors.sellerOrg}
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl sx={{ width: '33%' }}>
                            <Autocomplete
                                value={
                                    buyerOrganizations.find(
                                        (org) => org.uuid === selectedBuyerOrg?.uuid,
                                    ) || null
                                }
                                onChange={(event, newValue) => {
                                    setSelectedBuyerOrg(newValue);
                                    setSelectedAthlete(null);

                                    // Populate shipping address if organization has one
                                    if (newValue?.address) {
                                        setShippingAddress1(newValue.address.address_line1);
                                        setShippingAddress2(newValue.address.address_line2);
                                        setShippingCity(newValue.address.city);
                                        setShippingState(newValue.address.state);
                                        setShippingZip(newValue.address.postal_code);
                                    } else {
                                        setShippingAddress1('');
                                        setShippingAddress2('');
                                        setShippingCity('');
                                        setShippingState('');
                                        setShippingZip('');
                                    }
                                }}
                                onInputChange={(event, newInputValue, reason) => {
                                    if (reason !== 'reset') {
                                        fetchBuyerOrganizations(newInputValue);
                                    }
                                }}
                                getOptionKey={(option) => option.uuid}
                                filterOptions={(options) => options}
                                size={'small'}
                                options={buyerOrganizations}
                                getOptionLabel={(option) =>
                                    `${option.name} (${option.subscription_tier?.name}) - ${option.uuid}`
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Buyer Organization"
                                        error={!!formErrors.buyer && !selectedAthlete}
                                    />
                                )}
                                disabled={!!selectedAthlete}
                            />
                        </FormControl>
                        <FormControl sx={{ width: '33%' }}>
                            <Autocomplete
                                value={
                                    buyerAthletes.find(
                                        (athlete) => athlete.uuid === selectedAthlete?.uuid,
                                    ) || null
                                }
                                onChange={(event, newValue) => {
                                    setSelectedAthlete(newValue);
                                    setSelectedBuyerOrg(null);
                                }}
                                onInputChange={(event, newInputValue, reason) => {
                                    if (reason !== 'reset') {
                                        fetchBuyerAthletes(newInputValue);
                                    }
                                }}
                                getOptionKey={(option) => option.uuid}
                                filterOptions={(options) => options}
                                size={'small'}
                                options={buyerAthletes}
                                getOptionLabel={(option) =>
                                    `${option.first_name} ${option.last_name} (${option.user_name})`
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Buyer Athlete"
                                        error={!!formErrors.buyer && !selectedBuyerOrg}
                                    />
                                )}
                                disabled={!!selectedBuyerOrg}
                            />
                        </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', gap: 2, mb: 2, marginTop: '4px' }}>
                        <FormControl size="small" sx={{ width: '33%' }}>
                            <InputLabel>Order Type *</InputLabel>
                            <Select
                                value={orderType}
                                onChange={(e) =>
                                    setOrderType(e.target.value as 'comp' | 'replacement')
                                }
                                label="Order Type *"
                            >
                                <MenuItem value="replacement">Replacement</MenuItem>
                                <MenuItem value="comp">Comp</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Typography variant="body1" sx={{ fontWeight: '600' }}>
                        Shipping Information
                    </Typography>
                    <TextField
                        label="Address Line 1 *"
                        value={shippingAddress1}
                        onChange={(e) => setShippingAddress1(e.target.value)}
                        error={!!formErrors.shippingAddress1}
                        helperText={formErrors.shippingAddress1}
                        fullWidth
                        size="small"
                        sx={{ marginTop: '4px' }}
                    />
                    <TextField
                        label="Address Line 2"
                        value={shippingAddress2}
                        onChange={(e) => setShippingAddress2(e.target.value)}
                        fullWidth
                        size="small"
                        sx={{ marginTop: '4px' }}
                    />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                            label="City *"
                            value={shippingCity}
                            onChange={(e) => setShippingCity(e.target.value)}
                            error={!!formErrors.shippingCity}
                            helperText={formErrors.shippingCity}
                            sx={{ width: '50%', marginTop: '4px' }}
                            size="small"
                        />
                        <FormControl
                            sx={{ width: '25%', marginTop: '4px' }}
                            size="small"
                            error={!!formErrors.shippingState}
                        >
                            <InputLabel>State *</InputLabel>
                            <Select
                                value={shippingState}
                                onChange={(e) => setShippingState(e.target.value)}
                                label="State *"
                            >
                                {statesAndProvinces.map((state) => (
                                    <MenuItem key={state.abbreviation} value={state.abbreviation}>
                                        {state.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {formErrors.shippingState && (
                                <FormHelperText>{formErrors.shippingState}</FormHelperText>
                            )}
                        </FormControl>
                        <TextField
                            label="ZIP Code *"
                            value={shippingZip}
                            onChange={(e) => setShippingZip(e.target.value)}
                            error={!!formErrors.shippingZip}
                            helperText={formErrors.shippingZip}
                            sx={{ width: '25%', marginTop: '4px' }}
                            size="small"
                        />
                    </Box>

                    <Typography variant="body1" sx={{ fontWeight: '600', mt: 2 }}>
                        Line Items
                    </Typography>
                    {formErrors.lineItems && (
                        <Typography color="error" variant="caption">
                            {formErrors.lineItems}
                        </Typography>
                    )}
                    {lineItems.map((item, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                gap: 2,
                                mb: 2,
                                alignItems: 'center',
                                marginTop: '4px',
                            }}
                        >
                            <FormControl sx={{ width: '60%' }}>
                                <InputLabel size="small">Product</InputLabel>
                                <Select
                                    value={item.physical_product_uuid}
                                    onChange={(e) =>
                                        handleLineItemChange(
                                            index,
                                            'physical_product_uuid',
                                            e.target.value,
                                        )
                                    }
                                    label="Product"
                                    size="small"
                                >
                                    {physicalProducts.map((product) => (
                                        <MenuItem key={product.uuid} value={product.uuid}>
                                            <span style={{ fontSize: '0.8rem' }}>
                                                {product.name}
                                            </span>
                                            {'  '}
                                            <span
                                                style={{
                                                    fontSize: '0.7rem',
                                                    color: 'green',
                                                    fontStyle: 'italic',
                                                }}
                                            >
                                                ({product.inventory_count} available)
                                            </span>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                label="Quantity"
                                type="number"
                                value={item.quantity}
                                onChange={(e) => {
                                    const newValue = parseInt(e.target.value, 10);
                                    const product = physicalProducts.find(
                                        (p) => p.uuid === item.physical_product_uuid,
                                    );
                                    if (
                                        product &&
                                        newValue >= 1 &&
                                        newValue <= product.inventory_count
                                    ) {
                                        handleLineItemChange(index, 'quantity', newValue);
                                    }
                                }}
                                sx={{ width: '20%' }}
                                inputProps={{
                                    min: 1,
                                    max:
                                        physicalProducts.find(
                                            (p) => p.uuid === item.physical_product_uuid,
                                        )?.inventory_count || 1,
                                }}
                                size="small"
                            />
                            {index > 0 && (
                                <IconButton
                                    onClick={() => handleRemoveLineItem(index)}
                                    color="error"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </Box>
                    ))}
                    <Button
                        startIcon={<AddIcon />}
                        onClick={handleAddLineItem}
                        variant="outlined"
                        sx={{ mt: 1, width: '200px' }}
                    >
                        Add Line Item
                    </Button>

                    <TextField
                        label="Notes"
                        multiline
                        rows={4}
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit} variant="contained" color="primary">
                    Create Order
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OrderCreateDialog;
