import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    Menu,
    MenuItem,
    Autocomplete,
    TextField,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { Handle, Position } from 'reactflow';
import { formatCurrency } from '../../../app.functions';
import { convertOrganizationLevelToLevel, convertRankLevelToRank } from '../networking.functions';
import { PartnerNodeAdvancedInfo, Rank } from '../networking.types';
import React, { useRef, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { LinkOff, Link, DeleteOutline, DeleteForever, Shield } from '@mui/icons-material';
import { getNodeAdvancedInfo, getNodeAdvancedInfoForAdmin } from '../networking.api';
import { useParams } from 'react-router-dom';
import { Organization } from '../../../utils';
import debounce from 'lodash/debounce';
import { adminGetAllOrganizations } from '../../admin/admin.organizations.api';
import { adminChangeSponsor } from '../../admin/modules/binaryTree/binaryTree.api';

// Updated interface to include all properties
interface PartnerViewNodeProps {
    data: {
        partnerId: string;
        rank: number;
        matchesFilter: boolean;
        label: string;
        levels: {
            athleteCount: number;
            organizationSubscriptionLevel: number;
            marketplaceSales: number;
            [key: string]: number;
        };
        leadership_rank: number;
        context: {
            organization?: string;
            ranks: Rank[];
        };
        isAdmin?: boolean;
        isDetached?: boolean;
        isMarkedForDelete?: boolean;
        onDetach?: (partnerId: string) => void;
        onDelete?: (partnerId: string) => void;
        onSponsorChange?: (partnerId: string, sponsorId: string) => void;
        availableAttachments?: Array<{
            nodeId: string;
            name: string;
            position: 'L' | 'R';
        }>;
        onAttach?: (nodeId: string, parentId: string, position: 'L' | 'R') => void;
    };
}

function PartnerViewNode({ data }: PartnerViewNodeProps) {
    const { id } = useParams<{ id: string }>();
    const [open, setOpen] = useState(false);
    const [partnerData, setPartnerData] = useState<PartnerNodeAdvancedInfo | null>(null);
    const [attachMenuOpen, setAttachMenuOpen] = useState(false);
    const attachButtonRef = useRef<HTMLButtonElement>(null);

    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [loading, setLoading] = useState(false);

    const [confirmDialog, setConfirmDialog] = useState<{
        open: boolean;
        type: 'detach' | 'attach' | 'delete' | 'sponsor';
        title: string;
        message: string;
        onConfirm: () => void;
    }>({
        open: false,
        type: 'detach',
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const debouncedSearch = debounce((searchTerm: string) => {
        // If empty search, just keep the current sponsor in options
        if (!searchTerm) {
            if (partnerData?.sponsor?.organization) {
                setOrganizations([partnerData.sponsor.organization]);
            } else {
                setOrganizations([]);
            }
            return;
        }

        setLoading(true);
        adminGetAllOrganizations(searchTerm)
            .then((response) => {
                // Combine search results with current sponsor if it exists
                const results = response.data.data;
                if (partnerData?.sponsor?.organization) {
                    // Only add sponsor if it's not already in results
                    const sponsorExists = results.some(
                        (org) => org.uuid === partnerData.sponsor?.organization.uuid,
                    );
                    if (!sponsorExists) {
                        results.unshift(partnerData.sponsor.organization);
                    }
                }
                setOrganizations(results);
            })
            .catch((error) => {
                console.error('Error searching organizations:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, 300);

    const handleSponsorChange = (newValue: Organization | null) => {
        if (newValue) {
            setConfirmDialog({
                open: true,
                type: 'sponsor',
                title: 'Confirm Sponsor Change',
                message: `Are you sure you want to change the sponsor to ${newValue.name}?`,
                onConfirm: async () => {
                    try {
                        setLoading(true);
                        const response = await adminChangeSponsor(
                            data.partnerId,
                            newValue.partner?.uuid || '',
                        );
                        if (response.status === 200) {
                            handleInfoClick().then(() => {});
                        }
                    } catch (error: any) {
                        console.error('Error changing sponsor:', error);
                        // Check if it's an API error with a response
                        if (error.response) {
                            alert(
                                `Error changing sponsor: ${
                                    error.response.data.message || 'Unknown error'
                                }`,
                            );
                        } else {
                            alert('Error changing sponsor: Network error');
                        }
                    } finally {
                        setLoading(false);
                        setConfirmDialog((prev) => ({ ...prev, open: false }));
                    }
                },
            });
        }
    };

    const handleDetachClick = () => {
        setConfirmDialog({
            open: true,
            type: 'detach',
            title: 'Confirm Detach',
            message: `Are you sure you want to detach ${data.label}?`,
            onConfirm: () => {
                data.onDetach?.(data.partnerId);
                setConfirmDialog((prev) => ({ ...prev, open: false }));
            },
        });
    };

    const handleDeleteClick = () => {
        setConfirmDialog({
            open: true,
            type: 'delete',
            //title: 'Confirm Delete',
            title: 'Forbidden',
            //message: `Are you sure you want to delete ${data.label}? This will detach any children first. If this organization is a sponsor you must first select a new sponsor for all of its sponsees.`,
            message: `Deleting partners is not currently supported.`,
            // onConfirm: () => {
            //     data.onDelete?.(data.partnerId);
            //     setConfirmDialog((prev) => ({ ...prev, open: false }));
            // },
            onConfirm: () => {
                setConfirmDialog((prev) => ({ ...prev, open: false }));
            },
        });
    };

    const handleAttachClick = (parentId: string, position: 'L' | 'R') => {
        setConfirmDialog({
            open: true,
            type: 'attach',
            title: 'Confirm Attach',
            message: `Are you sure you want to attach ${data.label} to ${
                data.availableAttachments?.find(
                    (a) => a.nodeId === parentId && a.position === position,
                )?.name
            } (${position === 'L' ? 'Left' : 'Right'} leg)?`,
            onConfirm: () => {
                data.onAttach?.(data.partnerId, parentId, position);
                setAttachMenuOpen(false);
                setConfirmDialog((prev) => ({ ...prev, open: false }));
            },
        });
    };

    const handleInfoClick = async () => {
        setOpen(true);
        try {
            let response = null;
            if (data.isAdmin) {
                response = await getNodeAdvancedInfoForAdmin({ partnerId: data.partnerId });
            } else {
                response = await getNodeAdvancedInfo({ id: id, partnerId: data.partnerId });
            }
            setPartnerData(response.data);
            // Initialize organizations with current sponsor if it exists
            if (response.data.sponsor?.organization) {
                setOrganizations([response.data.sponsor.organization]);
            }
        } catch (error) {
            console.error('Error fetching partner data:', error);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setPartnerData(null);
    };

    return (
        <Paper
            sx={{
                backgroundColor: data.isMarkedForDelete ? '#ffebee' : 'white',
                width: 275,
                height: 225,
                opacity: data.matchesFilter ? 1 : 0.25,
                border: () => {
                    if (data.isMarkedForDelete) return '4px dashed #d32f2f';
                    if (data.isDetached) return '4px dashed red';
                    return '1px solid #ccc';
                },
                position: 'relative',
            }}
        >
            {(data.isDetached || data.isMarkedForDelete) && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: -16,
                        right: -16,
                        backgroundColor: data.isMarkedForDelete ? '#d32f2f' : 'red',
                        color: 'white',
                        borderRadius: '50%',
                        width: 32,
                        height: 32,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: data.isMarkedForDelete ? '20px' : '24px',
                        fontWeight: '600',
                        zIndex: 1000,
                    }}
                >
                    {data.isMarkedForDelete ? <DeleteForever fontSize="small" /> : '!'}
                </Box>
            )}
            <Handle type="target" position={Position.Top} />
            <Box sx={{ display: 'flex', flexDirection: 'column', fontSize: 16 }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: blueGrey[200],
                        padding: '4px',
                        fontWeight: 'bold',
                    }}
                >
                    {data.partnerId !== 'root' ? (
                        <IconButton size="small" onClick={handleInfoClick}>
                            <InfoIcon />
                        </IconButton>
                    ) : (
                        <Shield />
                    )}
                    {data.isAdmin ? (
                        <span
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                maxWidth: '160px',
                                display: 'inline-block',
                                cursor: 'default',
                            }}
                            title={data.label}
                        >
                            {data.label}
                        </span>
                    ) : (
                        <span>{data.label}</span>
                    )}
                    <Box>
                        {data.isAdmin && data.onDetach && !data.isMarkedForDelete && (
                            <IconButton
                                size="small"
                                title="Detach node from parent"
                                onClick={handleDetachClick}
                                sx={{ color: 'error.main' }}
                            >
                                <LinkOff />
                            </IconButton>
                        )}
                        {data.isAdmin && data.onDelete && !data.isDetached && (
                            <IconButton
                                size="small"
                                title="Delete node from tree"
                                onClick={handleDeleteClick}
                                sx={{
                                    color: 'error.main',
                                    ...(data.isMarkedForDelete && {
                                        backgroundColor: 'error.light',
                                        '&:hover': {
                                            backgroundColor: 'error.main',
                                        },
                                    }),
                                }}
                            >
                                <DeleteOutline />
                            </IconButton>
                        )}
                        {!data.isMarkedForDelete &&
                            data.isDetached &&
                            data.availableAttachments &&
                            data.availableAttachments.length > 0 && (
                                <>
                                    <IconButton
                                        ref={attachButtonRef}
                                        size="small"
                                        onClick={() => setAttachMenuOpen(true)}
                                        sx={{ color: 'success.main' }}
                                        title="Attach node to tree"
                                    >
                                        <Link />
                                    </IconButton>
                                    <Menu
                                        open={attachMenuOpen}
                                        onClose={() => setAttachMenuOpen(false)}
                                        anchorEl={attachButtonRef.current}
                                    >
                                        {data.availableAttachments.map((attachment) => (
                                            <MenuItem
                                                key={`${attachment.nodeId}-${attachment.position}`}
                                                onClick={() =>
                                                    handleAttachClick(
                                                        attachment.nodeId,
                                                        attachment.position,
                                                    )
                                                }
                                            >
                                                {attachment.name} ({attachment.position})
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </>
                            )}
                    </Box>
                </Box>
                {data.partnerId !== 'root' && (
                    <Box>
                        <TableContainer>
                            <Table size={'small'}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Current Rank</TableCell>
                                        <TableCell>
                                            <b>
                                                {convertRankLevelToRank(
                                                    data.rank,
                                                    data.context.ranks,
                                                )}
                                            </b>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Athletes</TableCell>
                                        <TableCell>{data.levels.athleteCount}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Org Subscription</TableCell>
                                        <TableCell>
                                            {convertOrganizationLevelToLevel(
                                                data.levels.organizationSubscriptionLevel,
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Marketplace Sales</TableCell>
                                        <TableCell>
                                            {formatCurrency({
                                                amount: data.levels.marketplaceSales / 100,
                                                currency: 'USD',
                                            })}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )}
            </Box>
            <Handle type="source" position={Position.Bottom} id="a" />
            <Dialog
                open={confirmDialog.open}
                onClose={() => setConfirmDialog((prev) => ({ ...prev, open: false }))}
            >
                <DialogTitle>{confirmDialog.title}</DialogTitle>
                <DialogContent>
                    <Typography>{confirmDialog.message}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialog((prev) => ({ ...prev, open: false }))}>
                        Cancel
                    </Button>
                    <Button onClick={confirmDialog.onConfirm} variant="contained" color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={open} onClose={handleClose}>
                {partnerData && (
                    <>
                        <DialogTitle>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {partnerData?.organization?.image_urls?.['200'] ? (
                                        <img
                                            src={partnerData.organization.image_urls['200']}
                                            height="32"
                                            alt="logo"
                                        />
                                    ) : (
                                        ''
                                    )}
                                    <Box sx={{ marginLeft: '10px' }}>
                                        <span>{partnerData.organization.name}</span>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Box style={{ lineHeight: '0.7', marginTop: '4px' }}>
                                        <span style={{ fontSize: '0.6rem', fontWeight: 400 }}>
                                            {partnerData.organization.creator?.first_name}{' '}
                                            {partnerData.organization.creator?.last_name}
                                        </span>
                                    </Box>
                                    <Box style={{ lineHeight: '0.7' }}>
                                        <span style={{ fontSize: '0.6rem', fontWeight: 400 }}>
                                            {partnerData.organization.creator?.phone}
                                        </span>
                                    </Box>
                                    <Box style={{ lineHeight: '0.7' }}>
                                        <span style={{ fontSize: '0.6rem', fontWeight: 400 }}>
                                            {partnerData.organization.creator?.email}
                                        </span>
                                    </Box>
                                </Box>
                            </Box>
                            {partnerData.shipping_address && (
                                <Box sx={{ marginTop: '10px' }}>
                                    <Typography
                                        variant="body2"
                                        component="div"
                                        sx={{ fontSize: '0.7rem' }}
                                    >
                                        {partnerData.shipping_address.line1}
                                    </Typography>
                                    {partnerData.shipping_address.line2 && (
                                        <Typography
                                            variant="body2"
                                            component="div"
                                            sx={{ fontSize: '0.7rem' }}
                                        >
                                            {partnerData.shipping_address.line2}
                                        </Typography>
                                    )}
                                    <Typography
                                        variant="body2"
                                        component="div"
                                        sx={{ fontSize: '0.7rem' }}
                                    >
                                        {partnerData.shipping_address.city},{' '}
                                        {partnerData.shipping_address.state}{' '}
                                        {partnerData.shipping_address.postal_code}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        component="div"
                                        sx={{ fontSize: '0.7rem' }}
                                    >
                                        {partnerData.shipping_address.country}
                                    </Typography>
                                </Box>
                            )}
                        </DialogTitle>
                        <Divider />
                        <DialogContent style={{ minWidth: '400px' }}>
                            <Box>
                                <TableContainer>
                                    <Table
                                        size={'small'}
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                borderBottom: 'none',
                                                fontSize: '0.7rem',
                                                marginLeft: 0,
                                            },
                                        }}
                                    >
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Current Rank</TableCell>
                                                <TableCell>
                                                    <b>
                                                        {convertRankLevelToRank(
                                                            data.rank,
                                                            data.context.ranks,
                                                        )}
                                                    </b>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Athletes</TableCell>
                                                <TableCell>{data.levels.athleteCount}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Org Subscription</TableCell>
                                                <TableCell>
                                                    {convertOrganizationLevelToLevel(
                                                        data.levels.organizationSubscriptionLevel,
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Marketplace Sales</TableCell>
                                                <TableCell>
                                                    {formatCurrency({
                                                        amount: data.levels.marketplaceSales / 100,
                                                        currency: 'USD',
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Commissions</TableCell>
                                                <TableCell>
                                                    {formatCurrency({
                                                        amount: partnerData.commissions / 100,
                                                        currency: 'USD',
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Payouts</TableCell>
                                                <TableCell>
                                                    {formatCurrency({
                                                        amount: partnerData.payouts / 100,
                                                        currency: 'USD',
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Organization Pod Subscriptions
                                                </TableCell>
                                                <TableCell>
                                                    {partnerData.active_pod_subscription_count_org}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Athlete Pod Subscriptions</TableCell>
                                                <TableCell>
                                                    {
                                                        partnerData.active_pod_subscription_count_athlete
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            {partnerData.sponsor?.organization && !data.isAdmin && (
                                                <TableRow>
                                                    <TableCell>Sponsor</TableCell>
                                                    <TableCell>
                                                        {partnerData.sponsor?.organization?.name ||
                                                            '-'}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                    {partnerData.sponsor?.organization && data.isAdmin && (
                                        <Autocomplete
                                            options={organizations}
                                            size="small"
                                            getOptionLabel={(option) => option.name}
                                            value={partnerData.sponsor?.organization || null}
                                            loading={loading}
                                            isOptionEqualToValue={(option, value) => {
                                                return option?.uuid === value?.uuid;
                                            }}
                                            onChange={(_, newValue) =>
                                                handleSponsorChange(newValue)
                                            }
                                            onInputChange={(_, newInputValue) => {
                                                debouncedSearch(newInputValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    label="Sponsor"
                                                    placeholder="Search organizations..."
                                                />
                                            )}
                                            sx={{ width: '90%', marginTop: '10px' }}
                                        />
                                    )}
                                </TableContainer>
                            </Box>
                        </DialogContent>
                        <Divider />
                        <DialogActions>
                            <Button variant="contained" onClick={handleClose}>
                                Close
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
            <Dialog
                open={confirmDialog.open}
                onClose={() => setConfirmDialog((prev) => ({ ...prev, open: false }))}
            >
                <DialogTitle>{confirmDialog.title}</DialogTitle>
                <DialogContent>
                    <Typography>{confirmDialog.message}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialog((prev) => ({ ...prev, open: false }))}>
                        Cancel
                    </Button>
                    <Button onClick={confirmDialog.onConfirm} variant="contained" color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
}

export default PartnerViewNode;
