import React, { useCallback, useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridLoadingOverlay, GridPaginationModel } from '@mui/x-data-grid';
import {
    Box,
    Container,
    Typography,
    CircularProgress,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Stack,
    TextField,
} from '@mui/material';
import { getInvoicesForOrganization, Invoice } from '../../api/Auth/getInvoicesForOrganization';
import { Organization } from '../../utils';

interface InvoicesPageProps {
    organization: Organization;
}

function CustomLoadingOverlay() {
    return (
        <GridLoadingOverlay
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px',
            }}
        >
            <CircularProgress />
        </GridLoadingOverlay>
    );
}

const InvoicesPage: React.FC<InvoicesPageProps> = ({ organization }) => {
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });
    const [formStatus, setFormStatus] = useState<'all' | 'paid' | 'open' | 'past_due'>('past_due');
    const [formStartDate, setFormStartDate] = useState<string | null>(null);
    const [formEndDate, setFormEndDate] = useState<string | null>(null);
    const [searchParams, setSearchParams] = useState({
        status: 'past_due' as 'all' | 'paid' | 'open' | 'past_due',
        startDate: null as string | null,
        endDate: null as string | null,
    });

    const isPastDue = (invoice: Invoice): boolean => {
        return invoice.status === 'open' && invoice.due_date < Date.now() / 1000;
    };

    const getComputedStatus = (invoice: Invoice): string => {
        if (isPastDue(invoice)) {
            return 'past_due';
        }
        return invoice.status;
    };

    const decodeStripeUrl = (url: string): string => {
        return url.replace(/\\/g, '');
    };

    const handleStatusChange = (event: SelectChangeEvent) => {
        setFormStatus(event.target.value as 'all' | 'paid' | 'open' | 'past_due');
    };

    const handleSearch = (event: React.FormEvent) => {
        event.preventDefault();
        setSearchParams({
            status: formStatus,
            startDate: formStartDate,
            endDate: formEndDate,
        });
        setPaginationModel((prev) => ({ ...prev, page: 0 }));
    };

    const columns: GridColDef[] = [
        {
            field: 'number',
            headerName: 'Invoice Number',
            flex: 1,
            minWidth: 130,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 120,
            renderCell: (params: any) => {
                const computedStatus = getComputedStatus(params.row);
                return (
                    <span
                        style={{
                            backgroundColor:
                                computedStatus === 'paid'
                                    ? '#4caf50'
                                    : computedStatus === 'past_due'
                                    ? '#f44336'
                                    : '#ffeb3b',
                            color:
                                computedStatus === 'paid'
                                    ? 'white'
                                    : computedStatus === 'past_due'
                                    ? 'white'
                                    : 'black',
                            padding: '4px 8px',
                            borderRadius: '4px',
                            textTransform: 'capitalize',
                        }}
                    >
                        {computedStatus}
                    </span>
                );
            },
        },
        {
            field: 'amount_due',
            headerName: 'Amount Due',
            flex: 1,
            minWidth: 120,
            valueFormatter: (value: any) => {
                return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(value / 100);
            },
        },
        {
            field: 'amount_paid',
            headerName: 'Amount Paid',
            flex: 1,
            minWidth: 120,
            valueFormatter: (value: any) => {
                return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(value / 100);
            },
        },
        {
            field: 'created',
            headerName: 'Created Date',
            flex: 1,
            minWidth: 160,
            valueFormatter: (value: any) => {
                return new Date(value * 1000).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                });
            },
        },
        {
            field: 'due_date',
            headerName: 'Due Date',
            flex: 1,
            minWidth: 160,
            valueFormatter: (params: any) => {
                return params?.value
                    ? new Date(params.value * 1000).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                      })
                    : 'N/A';
            },
        },
        {
            field: 'subscription_name',
            headerName: 'Type',
            flex: 1,
            minWidth: 160,
            renderCell: (params) => {
                return params.row.subscription_details.metadata?.name;
            },
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            minWidth: 120,
            renderCell: (params: any) => {
                return (
                    <Box sx={{ display: 'flex', gap: 1, marginTop: '8px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            href={decodeStripeUrl(params.row.hosted_invoice_url)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {params.row.status === 'open' ? 'Pay Invoice' : 'View Invoice'}
                        </Button>
                    </Box>
                );
            },
        },
    ];

    const fetchInvoices = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getInvoicesForOrganization(
                organization.uuid,
                paginationModel.pageSize,
                paginationModel.page + 1,
                searchParams.status,
                searchParams.startDate,
                searchParams.endDate,
            );

            setInvoices(response.data.data);
            setHasMore(response.data.meta.has_more);
        } catch (error) {
            console.error('Error fetching invoices:', error);
        } finally {
            setLoading(false);
        }
    }, [organization, paginationModel, searchParams]);

    useEffect(() => {
        fetchInvoices();
    }, [fetchInvoices]);

    return (
        <Container maxWidth={false} sx={{ width: '98%', mx: 'auto' }}>
            <Typography variant="h5" sx={{ mb: 3 }}>
                Invoices
            </Typography>
            <form onSubmit={handleSearch}>
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        mb: 3,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <FormControl sx={{ minWidth: 200 }}>
                        <InputLabel id="invoice-status-label">Invoice Status</InputLabel>
                        <Select
                            labelId="invoice-status-label"
                            id="invoice-status-select"
                            value={formStatus}
                            label="Invoice Status"
                            onChange={handleStatusChange}
                            size="small"
                        >
                            <MenuItem value="all">All Invoices</MenuItem>
                            <MenuItem value="paid">Paid</MenuItem>
                            <MenuItem value="open">Open</MenuItem>
                            <MenuItem value="past_due">Past Due</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Created Start Date"
                        type="date"
                        value={formStartDate}
                        onChange={(e) => {
                            setFormStartDate(e.target.value ?? null);
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        size="small"
                        sx={{ minWidth: 160 }}
                    />

                    <TextField
                        label="Created End Date"
                        type="date"
                        value={formEndDate}
                        onChange={(e) => {
                            setFormEndDate(e.target.value ?? null);
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        size="small"
                        sx={{ minWidth: 160 }}
                    />

                    <Button type="submit" variant="contained" color="primary">
                        Search
                    </Button>
                </Stack>
            </form>
            <Box sx={{ height: 600, width: '100%' }}>
                <DataGrid
                    rows={invoices}
                    columns={columns}
                    paginationModel={paginationModel}
                    onPaginationModelChange={(newModel) => {
                        if (!hasMore && newModel.page > paginationModel.page) {
                            return;
                        }
                        setPaginationModel(newModel);
                    }}
                    pagination
                    paginationMode="server"
                    rowCount={-1}
                    loading={loading}
                    pageSizeOptions={[5, 10, 25, 50]}
                    disableRowSelectionOnClick
                    getRowId={(row) => row.id}
                    slots={{
                        loadingOverlay: CustomLoadingOverlay,
                    }}
                />
            </Box>
        </Container>
    );
};

export default InvoicesPage;
