import SearchIcon from '@mui/icons-material/Search';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {
    Avatar,
    AvatarGroup,
    Box,
    Button,
    CircularProgress,
    Drawer,
    Grid,
    Hidden,
    Input,
    Skeleton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { getSignedUrl, uploadFile } from 'api';
import {
    AvatarWithInitials,
    confirmViaDialog,
    CustomButton,
    NextPrevPagination,
    RowDivider,
    SnackBar,
    ThumbnailTitleChip,
    TopBarWithShareIconLayout,
    UploadLogo,
} from 'components';
import { AbilityContext, Can } from 'components/Functional/Can';
import debounce from 'lodash/debounce';
import Lottie from 'lottie-react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { pushMessage } from 'redux/reducers/messages';
import { useAppDispatch } from 'redux/store';
import ROUTES from 'Routes/routes';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import {
    getIdFromEndOfRoute,
    Group,
    Individual,
    Member,
    MEMBER_TYPES,
    MemberType,
    Meta,
    PaginationLink,
    useToggle,
} from 'utils';
import Archive from '../../../lottie-animations/Parcel.json';
import SelectContentModal from '../../plan/components/SelectContentModal';
import {
    archiveGroup,
    deleteMember,
    getMembers,
    getMemberTypes,
    unarchiveGroup,
    updateGroup,
    viewGroup,
} from '../api/groups.api';
import {
    CreateMemberModal,
    GroupMemberCountChip,
    GroupMemberRow,
    GroupSharingDrawer,
    IndividualInviteDialog,
    MemberImportDialog,
    MemberSelectionDialog,
} from '../components';
import { ShareContentWithGroup } from './index';
import BulkMemberSelectionDialog from '../components/BulkMemberSelectionDialog';

const GroupDetails = () => {
    const [query, setQuery] = useQueryParams({
        'filter[name]': StringParam,
        'filter[managing_user_exists]': StringParam,
        page: NumberParam,
    });
    const [searchValue, setSearchValue] = useState<string>(query['filter[name]'] as string);
    const [openImportDialog, setOpenImportDialog] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<false | string>(false);
    const [group, setGroup] = useState<Group>();
    const [members, setMembers] = useState<Member[]>();
    const [membersPaginationLinks, setMembersPaginationLinks] = useState<PaginationLink>();
    const [membersPaginationMeta, setMembersPaginationMeta] = useState<Meta>();
    const [selectedIndividual, setSelectedIndividual] = useState<Individual | null>(null);
    const [loading, setLoading] = useState(true);
    const [groupName, setGroupName] = useState('');
    const [nameValue, setNameValue] = useState('');
    const [coaches, setCoaches] = useState<Array<Member>>([]);
    const [selectedLogo, setSelectedLogo] = useState<File>();
    const [uploadLogo, setUploadLogo] = useState<File>();
    const [notifyUserMessage, setNotifyUserMessage] = useState('');
    const [imageUploading, setImageUploading] = useState(false);
    const [loadingMembers, setLoadingMembers] = useState(false);
    const [individualToInvite, setIndividualToInvite] = useState<Individual | null>(null);
    const [selectedMemberIndex, setSelectedMemberIndex] = useState<number | null>(null);
    const [memberTypes, setMemberTypes] = useState<Array<MemberType>>([]);
    const [createMemberModalOpen, toggleCreateMemberModalOpen] = useToggle(false);
    const [memberSelectionModal, toggleMemberSelectionModal] = useToggle(false);
    const debounceLoadData = useCallback(debounce(setQuery, 400), []); // eslint-disable-line react-hooks/exhaustive-deps
    const debounceUpdateName = useCallback(debounce(setNameValue, 400), []); // eslint-disable-line react-hooks/exhaustive-deps
    const [sharingOpen, toggleSharing] = useToggle();
    const [sessionsModalOpen, toggleSessionsModal] = useToggle();
    const [assessmentsModalOpen, toggleAssessmentsModal] = useToggle();
    const [attachDrawerOpen, toggleAttachDrawerOpen] = useToggle();
    const { push, location } = useHistory();
    const dispatch = useAppDispatch();
    const [bulkMemberSelectionOpen, setBulkMemberSelectionOpen] = useState(false);

    const handleSearchValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (group) {
            setLoadingMembers(true);
            setSearchValue(event.target.value);
            debounceLoadData({ 'filter[name]': event.target.value, page: 1 });
        }
    };

    const reloadGroupAfterUpdates = () => {
        if (!group) {
            return;
        }

        viewGroup(group.uuid)
            .then((groupResponse) => {
                setGroupName(groupResponse.data.name);
                setGroup({ ...groupResponse.data });
            })
            .catch(() => setNotifyUserMessage('Failed to load group'));
    };

    useEffect(() => {
        let isMounted = true;
        const uuid = getIdFromEndOfRoute(location.pathname);

        viewGroup(uuid)
            .then((groupResponse) => {
                if (isMounted) {
                    setGroupName(groupResponse.data.name);
                    setGroup({ ...groupResponse.data });
                }
            })
            .catch(() => isMounted && setNotifyUserMessage('Failed to load group'))
            .finally(() => isMounted && setLoading(false));

        getMemberTypes().then((types) => isMounted && setMemberTypes(types.data));

        return () => {
            isMounted = false;
        };
    }, [location.pathname]);

    const reloadMemberList = () => {
        if (!group) {
            return;
        }

        getMembers(group?.uuid, {
            include: 'individual.managingUser,memberType',
            limit: 50,
            ...query,
        })
            .then((response) => {
                setMembers(response.data.data);
                setMembersPaginationLinks(response.data.links);
                setMembersPaginationMeta(response.data.meta);
            })
            .catch(() => setNotifyUserMessage('Failed to load search results'));
    };

    useEffect(() => {
        let isMounted = true;
        const uuid = getIdFromEndOfRoute(location.pathname);

        getMembers(uuid, {
            include: 'individual.managingUser,memberType',
            limit: 50,
            ...query,
        })
            .then((response) => {
                if (isMounted) {
                    setMembers(response.data.data);
                    setMembersPaginationLinks(response.data.links);
                    setMembersPaginationMeta(response.data.meta);
                }
            })
            .catch(() => isMounted && setNotifyUserMessage('Failed to load search results'))
            .finally(() => isMounted && setLoadingMembers(false));

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, query]);

    useEffect(() => {
        let isMounted = true;

        if (group && nameValue && nameValue !== group.name) {
            updateGroup({
                ...group,
                name: nameValue,
            })
                .then(() => isMounted && setGroup({ ...group, name: nameValue }))
                .catch(() => {
                    if (isMounted) {
                        setNotifyUserMessage('Failed to update name of group.');
                        setGroupName(group.name);
                    }
                });
        }

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameValue]);

    useEffect(() => {
        if (selectedMemberIndex !== null) {
            toggleCreateMemberModalOpen();
        }
    }, [selectedMemberIndex, toggleCreateMemberModalOpen]);

    const onCreateClose = (member: Member | false) => {
        toggleCreateMemberModalOpen();
        if (!member) {
            selectedMemberIndex !== null && setSelectedMemberIndex(null);
            return;
        }

        if (group) {
            if (selectedMemberIndex !== null) {
                const groupMembers = members ?? [];
                groupMembers[selectedMemberIndex] = member;
                setMembers(groupMembers);
                setSelectedMemberIndex(null);
            } else {
                setMembers([...(members ?? []), member]);
                setSelectedIndividual(null);
                reloadGroupAfterUpdates();
                dispatch(
                    pushMessage({
                        status: 'success',
                        message: 'Member successfully added to the group.',
                    }),
                );
            }

            member.memberType.name.toString() === MEMBER_TYPES.COACH &&
                setCoaches([...coaches, member]);
        }
    };

    const handleOnClose = async (file?: File) => {
        if (group) {
            setImageUploading(true);
            try {
                const response = await getSignedUrl();
                if (response.status === 201) {
                    const res = await uploadFile(response.data.url, file ?? uploadLogo);
                    if (res.status === 200) {
                        const upload = await updateGroup({ ...group, logo: response.data.key });
                        if (upload.status === 200) file && setUploadLogo(file);
                    }
                }
            } catch {
                setSelectedLogo(undefined);
                setNotifyUserMessage('Failed to upload the logo');
            } finally {
                setImageUploading(false);
            }
        }
    };

    const onUpdateNameOfGroup = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
        if (group) {
            setGroupName(event.target.value);
            debounceUpdateName(event.target.value);
        }
    };

    const onEditMember = (index: number) => {
        if (group) {
            setSelectedMemberIndex(index);
        }
    };

    const onDeleteMember = (id: string) => {
        setDeleting(id);
        confirmViaDialog({
            confirmation: {
                title: 'Are You Sure?',
                message: 'Once you delete this member they are removed from this group',
                cancelButtonText: 'Cancel',
                okButtonText: 'Delete',
            },
        })
            .then((result) => {
                if (result && group) {
                    deleteMember(group.uuid, id)
                        .then(() => reloadMemberList())
                        .catch(() => {});
                }
            })
            .finally(() => {
                setDeleting(false);
            });
    };

    const onIndividualInvited = (individual: Individual) => {
        setIndividualToInvite(individual);
    };

    const onNextClicked = () => {
        const nextPage = membersPaginationMeta ? membersPaginationMeta.current_page + 1 : 1;
        handlePageChanged(nextPage);
    };

    const onPrevClicked = () => {
        const prevPage = membersPaginationMeta ? membersPaginationMeta.current_page - 1 : 1;
        handlePageChanged(prevPage);
    };

    const handlePageChanged = (newPage: number) => {
        setQuery((query) => ({ ...query, page: newPage }));
    };

    const ability = useContext(AbilityContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <TopBarWithShareIconLayout
            title={group?.name}
            onBack={() => push(ROUTES.ListOfGroups.path)}
            onShare={toggleSharing}
            showShareButton={group && ability.can('group:update-members', group)}
        >
            {group && group.uuid && ability.can('group:update-members', group) && (
                <GroupSharingDrawer
                    groupUuid={group.uuid}
                    open={sharingOpen}
                    onClose={() => toggleSharing()}
                />
            )}
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    overflow: 'scroll',
                    '@media (max-width: 600px)': {
                        backgroundColor: (theme) => theme.palette.backgroundColor.action,
                    },
                }}
            >
                <Box
                    sx={{
                        width: '90%',
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingBottom: (theme) => theme.spacing(20),

                        '@media (max-width:600px)': {
                            // equivalent to theme.breakpoints.down('sm')
                            width: '100%',
                            padding: (theme) => theme.spacing(0, 8, 20),
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            margin: theme.spacing(8, 0),
                            marginTop: (theme) => theme.spacing(2),
                            '@media (max-width:600px)': {
                                // equivalent to theme.breakpoints.down('sm')
                                flexDirection: 'column',
                            },
                        }}
                    >
                        {group && (
                            <React.Fragment>
                                {imageUploading ? (
                                    <Skeleton
                                        sx={{
                                            width: '88px',
                                            height: '88px',
                                            borderRadius: '10%',
                                            marginRight: '10px',
                                        }}
                                    />
                                ) : (
                                    <React.Fragment>
                                        <Can I="group:update" this={group}>
                                            <UploadLogo
                                                uploadImage={selectedLogo ?? uploadLogo}
                                                setUploadImage={setSelectedLogo}
                                                onClose={handleOnClose}
                                                button={false}
                                                uploadContainerSx={{
                                                    width: '88px',
                                                    height: '88px',
                                                    borderRadius: '10%',
                                                    marginRight: '10px',
                                                }}
                                                uploadedImageSx={{
                                                    width: '88px',
                                                    height: '88px',
                                                    borderRadius: '10%',
                                                    marginRight: '10px',
                                                }}
                                                defaultImgSrc={group.image_urls.avatar || ''}
                                            />
                                        </Can>
                                        <Can not I="group:update" this={group}>
                                            <Avatar
                                                alt={group.name}
                                                src={group.image_urls.avatar || ''}
                                                sx={{
                                                    width: '88px',
                                                    height: '88px',
                                                    borderRadius: '10%',
                                                    marginRight: '10px',
                                                }}
                                                variant={'rounded'}
                                            />
                                        </Can>
                                    </React.Fragment>
                                )}
                                <Box display="flex">
                                    <Can I="group:add-members" this={group}>
                                        <CustomButton
                                            sx={{
                                                borderRadius: '24px',
                                                fontSize: '15px',
                                                padding: (theme) => theme.spacing(6, 8),
                                                marginRight: (theme) => theme.spacing(3),
                                                '@media (max-width:600px)': {
                                                    // equivalent to theme.breakpoints.down('sm')
                                                    marginTop: (theme) => theme.spacing(8),
                                                },
                                            }}
                                            variant="contained"
                                            color="primary"
                                            onClick={toggleMemberSelectionModal}
                                            disabled={loading || !group}
                                        >
                                            Add Member
                                        </CustomButton>
                                        <CustomButton
                                            sx={{
                                                borderRadius: '24px',
                                                fontSize: '15px',
                                                padding: (theme) => theme.spacing(6, 8),
                                                marginRight: (theme) => theme.spacing(3),
                                            }}
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => setBulkMemberSelectionOpen(true)}
                                            disabled={loading || !group}
                                        >
                                            Bulk Add Members
                                        </CustomButton>
                                    </Can>
                                    <Hidden lgDown>
                                        <Can I="group:add-members" this={group}>
                                            <Button
                                                variant={'outlined'}
                                                sx={{
                                                    borderRadius: '24px',
                                                    fontSize: '15px',
                                                    padding: (theme) => theme.spacing(6, 8),
                                                    marginRight: (theme) => theme.spacing(3),
                                                    '@media (max-width:600px)': {
                                                        // equivalent to theme.breakpoints.down('sm')
                                                        marginTop: (theme) => theme.spacing(8),
                                                    },
                                                }}
                                                color="primary"
                                                onClick={() => setOpenImportDialog(true)}
                                            >
                                                Import
                                            </Button>
                                            <MemberImportDialog
                                                open={openImportDialog}
                                                onClose={() => {
                                                    setOpenImportDialog(false);
                                                }}
                                                group={group}
                                                onSuccess={() => {
                                                    setOpenImportDialog(false);
                                                    reloadMemberList();
                                                    reloadGroupAfterUpdates();
                                                }}
                                            />
                                        </Can>
                                    </Hidden>
                                    {group && ability.can('group:update', group) && (
                                        <Box display={'flex'} justifyContent={'space-between'}>
                                            <CustomButton
                                                sx={{
                                                    borderRadius: '24px',
                                                    fontSize: '15px',
                                                    padding: (theme) => theme.spacing(6, 8),
                                                    marginRight: (theme) => theme.spacing(3),
                                                    '@media (max-width:600px)': {
                                                        // equivalent to theme.breakpoints.down('sm')
                                                        marginTop: (theme) => theme.spacing(8),
                                                    },
                                                }}
                                                onClick={() => toggleAttachDrawerOpen()}
                                                variant="outlined"
                                                color="primary"
                                            >
                                                Share Content
                                            </CustomButton>
                                            <Drawer
                                                PaperProps={{
                                                    style: { width: isMobile ? '100%' : '60%' },
                                                }}
                                                anchor={'right'}
                                                open={attachDrawerOpen}
                                                onBackdropClick={toggleAttachDrawerOpen}
                                            >
                                                <ShareContentWithGroup
                                                    onClose={toggleAttachDrawerOpen}
                                                    group={group}
                                                />
                                            </Drawer>
                                        </Box>
                                    )}
                                    {group && ability.can('group:update', group) && (
                                        <Box display={'flex'} justifyContent={'space-between'}>
                                            <CustomButton
                                                sx={{
                                                    borderRadius: '24px',
                                                    fontSize: '15px',
                                                    padding: (theme) => theme.spacing(6, 8),
                                                    marginRight: (theme) => theme.spacing(3),
                                                    '@media (max-width:600px)': {
                                                        // equivalent to theme.breakpoints.down('sm')
                                                        marginTop: (theme) => theme.spacing(8),
                                                    },
                                                }}
                                                onClick={() => toggleSessionsModal()}
                                                variant="outlined"
                                                color="primary"
                                            >
                                                Assign Session(s)
                                            </CustomButton>
                                            <CustomButton
                                                sx={{
                                                    borderRadius: '24px',
                                                    fontSize: '15px',
                                                    padding: (theme) => theme.spacing(6, 8),
                                                    marginRight: (theme) => theme.spacing(3),
                                                    '@media (max-width:600px)': {
                                                        // equivalent to theme.breakpoints.down('sm')
                                                        marginTop: (theme) => theme.spacing(8),
                                                    },
                                                }}
                                                onClick={() => {
                                                    push(`${location.pathname}/speed-management`);
                                                }}
                                                variant="outlined"
                                                color="primary"
                                            >
                                                Speed Management
                                            </CustomButton>
                                            {sessionsModalOpen && (
                                                <SelectContentModal
                                                    filters={{
                                                        'filter[organization]':
                                                            group?.organization?.uuid,
                                                    }}
                                                    open={sessionsModalOpen}
                                                    onClose={() => toggleSessionsModal()}
                                                    onSelect={(sessions) => {
                                                        push(
                                                            `/plan/sessions/${
                                                                Object.keys(sessions)[0]
                                                            }/assign-group/${group.uuid}`,
                                                        );
                                                    }}
                                                    fullScreen={isMobile}
                                                />
                                            )}
                                        </Box>
                                    )}
                                    {group && ability.can('group:update', group) && (
                                        <Box display={'flex'} justifyContent={'space-between'}>
                                            <CustomButton
                                                sx={{
                                                    borderRadius: '24px',
                                                    fontSize: '15px',
                                                    padding: (theme) => theme.spacing(6, 8),
                                                    marginRight: (theme) => theme.spacing(3),
                                                    '@media (max-width:600px)': {
                                                        // equivalent to theme.breakpoints.down('sm')
                                                        marginTop: (theme) => theme.spacing(8),
                                                    },
                                                }}
                                                onClick={() => toggleAssessmentsModal()}
                                                variant="outlined"
                                                color="primary"
                                            >
                                                Assign Assessment
                                            </CustomButton>
                                            {assessmentsModalOpen && (
                                                <SelectContentModal
                                                    filters={{
                                                        'filter[organization]':
                                                            group?.organization?.uuid,
                                                    }}
                                                    open={assessmentsModalOpen}
                                                    onClose={() => toggleAssessmentsModal()}
                                                    onSelect={(sessions) => {
                                                        push(
                                                            `/class/assessments/${
                                                                Object.keys(sessions)[0]
                                                            }/assign-group/${group.uuid}`,
                                                        );
                                                    }}
                                                    fullScreen={isMobile}
                                                    objectType={'form'}
                                                />
                                            )}
                                        </Box>
                                    )}
                                    <Box display={'flex'} justifyContent={'space-between'}>
                                        <Button
                                            variant={
                                                query['filter[managing_user_exists]'] !== 'true'
                                                    ? 'outlined'
                                                    : 'contained'
                                            }
                                            onClick={() => {
                                                let newQuery = { ...query };
                                                if (
                                                    query['filter[managing_user_exists]'] === 'true'
                                                ) {
                                                    newQuery['filter[managing_user_exists]'] =
                                                        undefined;
                                                } else {
                                                    newQuery['filter[managing_user_exists]'] =
                                                        'true';
                                                }
                                                setQuery(newQuery);
                                            }}
                                            sx={{ marginRight: 3, borderRadius: '24px' }}
                                        >
                                            {!query['filter[managing_user_exists]'] ? (
                                                <VerifiedUserIcon sx={{ color: '#1B9CEA' }} />
                                            ) : (
                                                ''
                                            )}

                                            {query['filter[managing_user_exists]'] === 'true'
                                                ? 'See All Athletes'
                                                : 'See Only Users'}
                                        </Button>
                                    </Box>
                                    {group && (
                                        <Can I={'group:update'} this={group}>
                                            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                                {!group.archived_at && (
                                                    <CustomButton
                                                        sx={{
                                                            borderRadius: '24px',
                                                            fontSize: '15px',
                                                            padding: (theme) => theme.spacing(6, 8),
                                                            marginRight: (theme) =>
                                                                theme.spacing(3),
                                                            '@media (max-width:600px)': {
                                                                // equivalent to theme.breakpoints.down('sm')
                                                                marginTop: (theme) =>
                                                                    theme.spacing(8),
                                                            },
                                                        }}
                                                        variant={'outlined'}
                                                        color="primary"
                                                        onClick={async () => {
                                                            setLoading(true);

                                                            if (!group?.uuid) {
                                                                return;
                                                            }

                                                            try {
                                                                await archiveGroup(group.uuid);
                                                                push('/community/groups');
                                                            } catch (e) {
                                                                setNotifyUserMessage(
                                                                    'Failed to archive group',
                                                                );
                                                            }

                                                            setLoading(false);
                                                        }}
                                                        disabled={loading}
                                                    >
                                                        <Lottie
                                                            animationData={Archive}
                                                            loop={false}
                                                            autoplay={true}
                                                            style={{
                                                                width: '30px', // Adjust the width and height as needed
                                                                height: '30px',
                                                            }}
                                                        />
                                                        Archive
                                                    </CustomButton>
                                                )}
                                                {group.archived_at && (
                                                    <CustomButton
                                                        color="primary"
                                                        onClick={async () => {
                                                            setLoading(true);

                                                            if (!group?.uuid) {
                                                                return;
                                                            }

                                                            try {
                                                                await unarchiveGroup(group.uuid);
                                                                push('/community/groups');
                                                            } catch (e) {
                                                                setNotifyUserMessage(
                                                                    'Failed to unarchive group',
                                                                );
                                                            }

                                                            setLoading(false);
                                                        }}
                                                        disabled={loading}
                                                    >
                                                        Unarchive
                                                    </CustomButton>
                                                )}
                                            </Box>
                                        </Can>
                                    )}
                                </Box>
                                <GroupMemberCountChip group={group} />
                            </React.Fragment>
                        )}
                    </Box>

                    {loading ? (
                        <Skeleton
                            sx={{
                                color: (theme) => theme.palette.text.primary,
                                fontWeight: 600,
                                fontSize: '36px',
                                lineHeight: '42px',
                                margin: (theme) => theme.spacing(8, 0, 0),
                                '&:before': {
                                    borderBottom: 'none',
                                },
                                '&:after': {
                                    borderBottom: 'none',
                                },
                            }}
                        />
                    ) : (
                        <Input
                            onChange={onUpdateNameOfGroup}
                            disabled={!group}
                            value={groupName}
                            placeholder="Group Name"
                            sx={{
                                color: (theme) => theme.palette.text.primary,
                                fontWeight: 600,
                                fontSize: '36px',
                                lineHeight: '42px',
                                margin: (theme) => theme.spacing(8, 0, 0),
                                '&:before': {
                                    borderBottom: 'none',
                                },
                                '&:after': {
                                    borderBottom: 'none',
                                },
                            }}
                        />
                    )}

                    <Input
                        onChange={handleSearchValueChanged}
                        value={searchValue}
                        sx={{
                            width: '100%',
                            borderRadius: '32px',
                            padding: (theme) => theme.spacing(5),
                            background: (theme) => theme.palette.background.paper,
                            margin: (theme) => theme.spacing(16, 0),
                            color: (theme) => theme.palette.text.secondary,
                            '&:before': {
                                borderBottom: 'none',
                            },
                            '&:after': {
                                borderBottom: 'none',
                            },
                            '&::placeholder': {
                                color: (theme) => theme.palette.primary.main,
                                marginLeft: (theme) => theme.spacing(6),
                            },
                            '& > input': {
                                marginLeft: (theme) => theme.spacing(6),
                            },
                        }}
                        startAdornment={<SearchIcon />}
                        placeholder="Search"
                        endAdornment={loadingMembers && <CircularProgress size={30} />}
                        disabled={!group}
                    />
                    {group?.organization && (
                        <ThumbnailTitleChip
                            title={group.organization.name}
                            src={group.organization.logo_url ?? ''}
                            avatarVariant="rounded"
                            sx={{
                                backgroundColor: '#ffffff',
                                display: 'flex',
                                width: 'auto',
                                gap: (theme) => theme.spacing(8),
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: (theme) => theme.spacing(8),
                                padding: (theme) => theme.spacing(8, 6),
                                borderRadius: 4,
                                boxShadow: (theme) => theme.shadows[1],
                                '& .title': {
                                    fontSize: 20,
                                    fontWeight: 500,
                                },
                                '& .thumbnail': {
                                    height: 44,
                                    width: 44,
                                },
                            }}
                        />
                    )}
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            background: theme.palette.backgroundColor.paper,
                            marginTop: 4,
                        }}
                    >
                        {coaches.length > 0 && (
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: '100%',
                                        alignItems: 'center',
                                        padding: theme.spacing(8),
                                    }}
                                >
                                    <AvatarGroup max={3}>
                                        {coaches.map((coach, index) => (
                                            <AvatarWithInitials
                                                sx={{ width: '60px', height: '60px' }}
                                                key={index}
                                                firstname={coach.first_name}
                                                lastname={coach.last_name}
                                            />
                                        ))}
                                    </AvatarGroup>
                                    <Typography
                                        sx={{
                                            width: '50%',
                                            marginLeft: theme.spacing(8),
                                            fontWeight: 'normal',
                                            color: theme.palette.text.secondary,
                                            fontSize: '14px',
                                            lineHeight: '20px',

                                            [theme.breakpoints.down('sm')]: {
                                                width: 'auto',
                                            },
                                        }}
                                    >
                                        {coaches.map((coach, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    color: theme.palette.text.primary,
                                                    fontWeight: 700,
                                                }}
                                            >{`${coach.first_name ?? ''} ${
                                                coach.last_name ?? ''
                                            }, `}</span>
                                        ))}
                                        {coaches.length > 1 ? ' are coaches' : ' is coach'}
                                        {' of this group'}
                                    </Typography>
                                </Box>
                                <RowDivider />
                            </Box>
                        )}
                        {loading ? (
                            <Skeleton sx={{ width: '100%', height: '500px' }} />
                        ) : (
                            <Grid container spacing={3}>
                                {members?.map((member, index) => (
                                    <Grid item xs={12} md={6} lg={6} key={index}>
                                        <GroupMemberRow
                                            key={index}
                                            deleting={deleting ? deleting === member.uuid : false}
                                            group={group}
                                            member={member}
                                            onEditMember={() => onEditMember(index)}
                                            onDeleteMember={() => onDeleteMember(member.uuid)}
                                            onInviteClicked={onIndividualInvited}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Box>
                    {members && (
                        <>
                            <NextPrevPagination
                                onNext={() => onNextClicked()}
                                onPrev={() => onPrevClicked()}
                                disablePrev={Boolean(!membersPaginationLinks?.prev)}
                                disableNext={Boolean(!membersPaginationLinks?.next)}
                            />
                            {isMobile && <div style={{ marginBottom: '40px' }}></div>}
                        </>
                    )}
                    <SnackBar
                        open={notifyUserMessage ? true : false}
                        setOpen={() => setNotifyUserMessage('')}
                        severity="error"
                        message={notifyUserMessage}
                    />
                    {group && (
                        <CreateMemberModal
                            group={group}
                            open={createMemberModalOpen}
                            onClose={onCreateClose}
                            memberTypes={memberTypes}
                            selectedIndividual={selectedIndividual}
                            editMember={
                                members && selectedMemberIndex !== null
                                    ? members[selectedMemberIndex]
                                    : null
                            }
                        />
                    )}
                </Box>
            </Box>
            {!!individualToInvite && (
                <IndividualInviteDialog
                    open={true}
                    onClose={() => setIndividualToInvite(null)}
                    individual={individualToInvite}
                />
            )}
            {group && memberSelectionModal && (
                <MemberSelectionDialog
                    open={memberSelectionModal}
                    organizationUuid={group?.organization?.uuid}
                    onCancel={() => {
                        toggleMemberSelectionModal();
                    }}
                    onIndividualSelected={(individual: Individual) => {
                        setSelectedIndividual(individual);
                        toggleMemberSelectionModal();
                        toggleCreateMemberModalOpen();
                    }}
                    onNewButtonpressed={() => {
                        toggleCreateMemberModalOpen();
                        toggleMemberSelectionModal();
                    }}
                    group={group}
                />
            )}
            {group && memberTypes.length > 0 && (
                <BulkMemberSelectionDialog
                    open={bulkMemberSelectionOpen}
                    onClose={() => setBulkMemberSelectionOpen(false)}
                    organizationUuid={group?.organization?.uuid}
                    group={group}
                    memberType={memberTypes[0]}
                    onSuccess={() => {
                        reloadMemberList();
                        reloadGroupAfterUpdates();
                    }}
                />
            )}
        </TopBarWithShareIconLayout>
    );
};

export default GroupDetails;
