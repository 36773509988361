import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogTitle, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { TypographyTitle } from '../../../components';
import LandingAuthScreenLayout from '../../../components/Layouts/LandingAuthScreenLayout';
import DbLogo from '../../../images/db_icon.png';
import Lottie from 'lottie-react';
import RegisterAnimation from '../../../lottie-animations/sign-up.json';
import LoginAnimation from '../../../lottie-animations/log-in.json';
import DialogContent from '@mui/material/DialogContent';
import { Shield, SupervisorAccount, Person } from '@mui/icons-material';

const LandingPage = () => {
    const history = useHistory();
    const handleLoginClick = () => {
        history.push('/login');
    };
    const [userWantsToRegister, setUserWantsToRegister] = React.useState(false);

    return (
        <LandingAuthScreenLayout>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                minHeight="50%" // This ensures the content takes the full height of the parent
            >
                <Typography
                    sx={{ mt: 4, mb: 2, display: 'flex', alignItems: 'center' }}
                    variant="subtitle1"
                    component="div"
                >
                    <img src={DbLogo} alt="1TUL Logo" width={90} />
                </Typography>
                <TypographyTitle align="center">
                    1TUL to Prove Performance Improvement.
                </TypographyTitle>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Lottie
                            animationData={LoginAnimation}
                            loop={true}
                            autoplay={true}
                            style={{ width: '100px', height: '100px', cursor: 'pointer' }}
                            onClick={handleLoginClick}
                        />
                    </Grid>
                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Lottie
                            animationData={RegisterAnimation}
                            loop={true}
                            autoplay={true}
                            style={{ width: '120px', height: '120px', cursor: 'pointer' }}
                            onClick={() => setUserWantsToRegister(true)}
                        />
                    </Grid>
                </Grid>
                <Typography
                    sx={{ mt: 16, mb: 2, display: 'flex', alignItems: 'center' }}
                    variant="subtitle1"
                    component="div"
                >
                    Additional Technology Required: GPS, 1080 Motion, or smart phone with 60 fps
                    camera is required for most 1TUL features.
                </Typography>
            </Box>
            <Dialog open={userWantsToRegister} onClose={() => setUserWantsToRegister(false)}>
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Shield />
                        Invite Only Access
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Our platform is currently invite-only. Please follow the appropriate path
                        based on your role.
                    </Typography>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={0}
                        style={{
                            marginTop: '16px',
                            padding: '8px',
                            border: '1px solid lightgray',
                            borderRadius: '8px',
                        }}
                    >
                        <Box display="flex" alignItems="center" gap={3}>
                            <SupervisorAccount />
                            <Box display="flex" flexDirection="column">
                                <Typography style={{ fontSize: '1rem', fontWeight: '600' }}>
                                    I am a Coach
                                </Typography>
                                <Typography style={{ fontSize: '0.8rem', fontWeight: '400' }}>
                                    Contact a sponsor for an invitation
                                </Typography>
                            </Box>
                        </Box>

                        {/* Separator */}
                        <Box>
                            <Typography variant="body1" component="h2">
                                or
                            </Typography>
                        </Box>

                        <Box display="flex" alignItems="center" gap={3}>
                            <Person />
                            <Box display="flex" flexDirection="column">
                                <Typography style={{ fontSize: '1rem', fontWeight: '600' }}>
                                    I am an Athlete
                                </Typography>
                                <Typography style={{ fontSize: '0.8rem', fontWeight: '400' }}>
                                    Contact your coach for an invitation
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setUserWantsToRegister(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </LandingAuthScreenLayout>
    );
};

export default LandingPage;
