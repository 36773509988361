import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { AttributeValue, Member } from 'utils';

type ReturnType = Promise<AxiosResponse<Member[]>>;

export interface BulkMemberPayload {
    first_name: string;
    last_name: string;
    attributes: Array<AttributeValue>;
    member_type: string;
    individual?: string | null;
    profile_photo?: string;
}

const bulkCreateMembers = (group: string, members: BulkMemberPayload[]): ReturnType => {
    return Request.post(`/groups/${group}/members-bulk/`, { members });
};

export default bulkCreateMembers;
