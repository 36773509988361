import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { UpdateOrderParams, Order } from './orders.types';

interface PaginatedApiResponse {
    data: Array<Order>;
    links: any; // Replace 'any' with the actual type of 'links'
    meta: any; // Replace 'any' with the actual type of 'meta'
}

type ReturnTypeCollection = Promise<AxiosResponse<PaginatedApiResponse>>;

interface GetOrdersParams {
    order_status?:
        | 'all'
        | ''
        | 'received'
        | 'processing'
        | 'shipping'
        | 'completed'
        | 'cancelled'
        | 'backfilled'
        | 'archived';
    order_type?: 'all' | '' | 'physical_product' | 'subscription' | 'comp' | 'replacement';
    seller_org_uuid?: string;
    buyer_org_uuid?: string;
    athlete_uuid?: string;
    sort?: 'created_at' | '-created_at';
    page?: number;
    per_page?: number;
}

interface OrderLineItem {
    physical_product_uuid: string;
    quantity: number;
    amount: number;
    tax: number;
}

interface CreateOrderParams {
    seller_org_uuid: string;
    buyer_org_uuid?: string;
    athlete_uuid?: string;
    tax: number;
    shipping: number;
    shipping_address_1: string;
    shipping_address_2?: string;
    shipping_city: string;
    shipping_state: string;
    shipping_zip: string;
    order_type: 'physical_product' | 'subscription' | 'comp' | 'replacement';
    order_notes: string;
    product_line_items: OrderLineItem[];
    notes?: string;
}

const getOrders = (params?: GetOrdersParams): ReturnTypeCollection => {
    if (params?.order_type === 'all') {
        params.order_type = '';
    }

    if (params?.order_status === 'all') {
        params.order_status = '';
    }

    const filterParams = {
        'filter[order_status]': params?.order_status,
        'filter[order_type]': params?.order_type,
        'filter[seller_org_uuid]': params?.seller_org_uuid,
        'filter[buyer_org_uuid]': params?.buyer_org_uuid,
        'filter[athlete_uuid]': params?.athlete_uuid,
        sort: params?.sort,
        page: params?.page,
        per_page: params?.per_page,
    };
    return Request.get(`admin/marketplace/orders`, { params: filterParams });
};

const createOrder = (orderData: CreateOrderParams): ReturnTypeSingle => {
    return Request.post('admin/marketplace/orders', orderData);
};

type ReturnTypeSingle = Promise<AxiosResponse<Order>>;

const updateOrder = (orderUuid: string, orderData: UpdateOrderParams): ReturnTypeSingle => {
    return Request.put(`admin/marketplace/orders/${orderUuid}`, orderData);
};

type ReturnTypeOrderStatuses = Promise<AxiosResponse<Array<string>>>;

const getOrderStatuses = (): ReturnTypeOrderStatuses => {
    return Request.get(`admin/marketplace/orders/order-statuses`);
};

export { updateOrder, getOrders, getOrderStatuses, createOrder };
export type { UpdateOrderParams, GetOrdersParams, CreateOrderParams, OrderLineItem };
