import React, { useEffect, useState } from 'react';
import {
    Box,
    TextField,
    Button,
    useTheme,
    Snackbar,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogActions,
    Autocomplete,
} from '@mui/material';
import { DataGridPremium, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { adminGetAthletes, assignAthleteToPartner } from '../athletes.api';
import { Athlete } from '../athletes.types';
import { styled } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DialogContent from '@mui/material/DialogContent';
import { Organization } from '../../../../../utils';
import debounce from 'lodash/debounce';
import { adminGetAllOrganizations } from '../../../admin.organizations.api';

const ResponsiveFormItem = styled(Box)(({ theme }) => ({
    width: 'auto',
    flexGrow: 0,
    margin: theme.spacing(1),
}));

const ResponsiveForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    margin: theme.spacing(-1),
}));

interface AssignModalState {
    open: boolean;
    athlete: Athlete | null;
}

const AthleteList = () => {
    const theme = useTheme();
    const [athletes, setAthletes] = useState<Athlete[]>([]);
    const [nameFilter, setNameFilter] = useState('');
    const [snackMessage, setSnackMessage] = useState('');
    const [modalState, setModalState] = useState<AssignModalState>({
        open: false,
        athlete: null,
    });
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    });
    const [totalRows, setTotalRows] = useState(0);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);

    const fetchOrganizations = debounce((searchTerm: string) => {
        adminGetAllOrganizations(searchTerm)
            .then((response) => {
                setOrganizations(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch organizations:', error);
            });
    }, 300);

    useEffect(() => {
        fetchOrganizations('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAssignClick = (athlete: Athlete) => {
        setModalState({
            open: true,
            athlete,
        });
    };

    const handleModalClose = () => {
        setModalState({
            open: false,
            athlete: null,
        });
    };

    const handleAssignConfirm = async () => {
        if (!modalState.athlete) return;

        try {
            await assignAthleteToPartner(
                modalState.athlete.user_uuid,
                modalState.athlete.partner_uuid as string,
            );

            setSnackMessage(
                `Athlete ${modalState.athlete.name} assigned successfully to organization ${modalState.athlete.organization_name}`,
            );
            await handleSearch();
            handleModalClose();
        } catch (error: any) {
            handleModalClose();
            console.log('Failed to assign athlete:', error);
            setSnackMessage(`Failed to assign athlete: ${error.message}`);
        }
    };

    const handleSearch = async (event?: React.FormEvent) => {
        if (event) {
            event.preventDefault();
        }
        try {
            const filters: Record<string, any> = {
                'filter[name]': nameFilter,
                per_page: paginationModel.pageSize,
                page: paginationModel.page + 1,
            };

            if (selectedOrganization) {
                filters['filter[organization_uuid]'] = selectedOrganization.uuid;
            }

            const response = await adminGetAthletes(filters);
            setAthletes(response.data.data);
            setTotalRows(response.data.meta.total);
        } catch (error) {
            console.error('Failed to fetch athletes:', error);
        }
    };

    const handlePaginationChange = async (newModel: typeof paginationModel) => {
        setPaginationModel(newModel);
        try {
            const filters: Record<string, any> = {
                'filter[name]': nameFilter,
                per_page: newModel.pageSize,
                page: newModel.page + 1,
            };

            if (selectedOrganization) {
                filters['filter[organization_uuid]'] = selectedOrganization.uuid;
            }

            const response = await adminGetAthletes(filters);
            setAthletes(response.data.data);
            setTotalRows(response.data.meta.total);
        } catch (error) {
            console.error('Failed to fetch athletes:', error);
        }
    };

    const columns: GridColDef<Athlete>[] = [
        {
            field: 'individual_uuid',
            headerName: 'Individual',
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<Athlete>) => {
                return `${params.row.name} | ${params.row.individual_uuid}`;
            },
        },
        {
            field: 'user_info',
            headerName: 'User',
            flex: 1.5,
            minWidth: 200,
            renderCell: (params: GridRenderCellParams<Athlete>) => {
                const email = params.row.email || '';
                const phone = params.row.phone || '';
                const emailPhone = `${email}${email && phone ? ' | ' : ''}${phone}`;
                return `${params.row.first_name} ${params.row.last_name} | ${params.row.user_name} | ${emailPhone} | ${params.row.user_uuid}`;
            },
        },
        {
            field: 'organization_name',
            headerName: 'Organization',
            flex: 1,
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<Athlete>) => {
                if (!params.row.organization_uuid) return '';
                return `${params.row.organization_name} | ${params.row.organization_tier} | ${params.row.organization_uuid}`;
            },
        },
        {
            field: 'is_assigned',
            headerName: 'Is Assigned',
            flex: 0.5,
            minWidth: 100,
            renderCell: (params: GridRenderCellParams<Athlete>) => {
                const isAssigned =
                    params.row.assigned_partner_key !== null &&
                    params.row.assigned_partner_key === params.row.partner_key;
                return isAssigned ? <CheckCircleIcon sx={{ color: 'green' }} /> : '';
            },
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.5,
            minWidth: 100,
            renderCell: (params: GridRenderCellParams<Athlete>) => {
                const isAssigned =
                    params.row.assigned_partner_key !== null &&
                    params.row.assigned_partner_key === params.row.partner_key;
                const showAssignButton =
                    !isAssigned && params.row.organization_uuid && params.row.partner_uuid;

                if (!showAssignButton) return null;

                return (
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleAssignClick(params.row)}
                    >
                        Assign
                    </Button>
                );
            },
        },
    ];

    return (
        <Box sx={{ margin: theme.spacing(0, 2) }}>
            <Snackbar
                open={!!snackMessage}
                message={snackMessage}
                autoHideDuration={6000}
                onClose={() => setSnackMessage('')}
            />
            <Dialog
                open={modalState.open}
                onClose={handleModalClose}
                aria-labelledby="assign-dialog-title"
            >
                <DialogTitle id="assign-dialog-title">Confirm Assignment</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you want to associate user {modalState.athlete?.name} (
                        {modalState.athlete?.user_name}) with organization{' '}
                        {modalState.athlete?.organization_name}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAssignConfirm} color="primary" variant="contained">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <h2>Athlete Search</h2>
            <section style={{ marginBottom: theme.spacing(2) }}>
                <h4>Filter Athletes</h4>
                <ResponsiveForm onSubmit={handleSearch} noValidate>
                    <ResponsiveFormItem>
                        <TextField
                            label="Search by Name"
                            value={nameFilter}
                            onChange={(e) => setNameFilter(e.target.value)}
                            size="small"
                            sx={{ width: '250px' }}
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <Autocomplete
                            value={selectedOrganization}
                            onChange={(event, newValue) => {
                                setSelectedOrganization(newValue);
                            }}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason !== 'reset') {
                                    fetchOrganizations(newInputValue);
                                }
                            }}
                            isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                            getOptionLabel={(option) =>
                                `${option.name} (${option.subscription_tier?.name}) ${option.uuid}`
                            }
                            options={organizations}
                            sx={{ width: '300px' }}
                            size="small"
                            renderInput={(params) => (
                                <TextField {...params} label="Select Organization" />
                            )}
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <Button
                            type="submit"
                            variant="contained"
                            size="medium"
                            sx={{ height: '40px' }}
                        >
                            Search Athletes
                        </Button>
                    </ResponsiveFormItem>
                </ResponsiveForm>
            </section>

            <Box width="98%" maxHeight="600px" overflow="auto">
                <DataGridPremium<Athlete>
                    rows={athletes}
                    columns={columns}
                    getRowId={(row) => row.individual_uuid}
                    paginationMode="server"
                    pagination
                    rowCount={totalRows}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationChange}
                    pageSizeOptions={[10, 25, 50, 100]}
                    disableColumnMenu
                    style={{ height: '624px', marginBottom: '50px' }}
                    sx={{
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-cell': {
                            fontSize: '0.6rem',
                        },
                        '& .MuiDataGrid-columnHeader': {
                            fontSize: '0.6rem',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontSize: '0.6rem',
                        },
                        '& .MuiDataGrid-footerContainer': {
                            fontSize: '0.6rem',
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

export default AthleteList;
