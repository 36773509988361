import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Container,
    Dialog,
    Grid,
    Paper,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
    useNetworkingBinaryTree,
    useNetworkingOrganization,
    useNetworkingRanks,
} from '../state/NetworkingContext';
import DashboardCard from './DashboardCard';
import { CopyLink } from '../../../components';
import DialogContent from '@mui/material/DialogContent';
import QRCode from 'react-qr-code';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import DownloadIcon from '@mui/icons-material/Download';
import { HowToReg as HowToRegIcon } from '@mui/icons-material';
import Logo from '../../../logos/FiyrPodBlackBG.png';
import LogoWB from '../../../logos/FiyrPodLogoWBG.png';
import FoundersSummitPage1 from '../../../logos/FoundersSummitPage1.png';
import FoundersSummitPage2 from '../../../logos/FoundersSummitPage2.png';
import { convertOrganizationLevelToLevel, convertRankLevelToRank } from '../networking.functions';
import { getOrgDashboardMetrics } from '../networking.api';
import { AdvancedOrganization } from '../networking.types';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PartnershipDashboardScene = () => {
    const binaryTree = useNetworkingBinaryTree();
    const organization = useNetworkingOrganization();
    const [organizationData, setOrganizationData] = useState<AdvancedOrganization>();
    const [data, setData] = useState<{ title: string; value: number | string }[]>([]);
    const [openQR, setOpenQR] = React.useState(false);
    const ranksData = useNetworkingRanks();
    const handleClickOpenQR = () => {
        setOpenQR(true);
    };

    const handleCloseQR = () => {
        setOpenQR(false);
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = Logo; // Ensure Logo is a valid path to the image file
        link.download = 'logo.png'; // The filename for the downloaded image
        link.click();
    };

    useEffect(() => {
        getOrgDashboardMetrics(organization.data?.uuid as string, {
            organization_uuid: organization.data?.uuid,
        })
            .then((response) => {
                setOrganizationData(response.data.data[0]);
            })
            .catch((error) => {
                console.error('Failed to fetch organizations', error);
            });
    }, [organization]);

    useEffect(() => {
        if (!binaryTree.data || !organizationData) {
            setData([]);
            return;
        }
        const d = [
            {
                title: 'Current Rank',
                value: convertRankLevelToRank(binaryTree.data.rank, ranksData.data || []),
            },
            { title: 'Current Leadership Rank', value: binaryTree.data.leadership_rank },
            {
                title: 'Marketplace Sales',
                value: `$${(binaryTree.data.levels.marketplaceSales / 100).toFixed(2)}`,
            },
            { title: 'Paid Athlete Count', value: organizationData.athlete_count || 0 },
            {
                title: 'Organization Subscription Level',
                value: convertOrganizationLevelToLevel(
                    binaryTree.data.levels.organizationSubscriptionLevel,
                ),
            },
        ];
        setData(d);
    }, [binaryTree.data, ranksData.data, organizationData]);

    return (
        <Container
            sx={{
                flex: 1,
            }}
            maxWidth={'xl'}
        >
            <Paper
                sx={{ margin: 6, background: 'linear-gradient(to bottom, white 30%, #EDF1F8 70%)' }}
            >
                <Box sx={{ width: '100%' }}>
                    <Typography sx={{ mt: 10, mb: 10 }} textAlign="center" variant="h6">
                        Your Organization Status and Rank
                    </Typography>
                    <Box
                        sx={{
                            display: 'grid',
                            gap: 4,
                            gridTemplateColumns: {
                                xs: '1fr',
                                sm: 'repeat(3, 1fr)',
                                md: 'repeat(5, 1fr)',
                            },
                            mt: 2,
                        }}
                    >
                        {data.map((item) => (
                            <DashboardCard key={item.title} title={item.title} value={item.value} />
                        ))}
                    </Box>
                </Box>
            </Paper>
            <Paper
                sx={{ margin: 6, background: 'linear-gradient(to bottom, white 30%, #EDF1F8 70%)' }}
            >
                <Box>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid container direction="column" alignItems="center">
                            <Grid item>
                                <Typography sx={{ mt: 10, mb: 10 }} textAlign="center" variant="h6">
                                    New Organization Sign Up Link!
                                </Typography>
                                <Typography
                                    sx={{ marginTop: 20, marginBottom: 20 }}
                                    variant="subtitle1"
                                >
                                    Use this link or QR code to ensure you become the Direct Sponsor
                                    of the New Organizations and they are placed in your 1st Level
                                    Enroller Tree
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <CopyLink
                                disabled={!organization.data?.uuid}
                                onCopyToClipboard={() => {
                                    console.log('copied');
                                }}
                                link={`${window.location.host}/invite-organization/${organization.data?.uuid}`}
                            />
                        </Grid>
                        <Grid container direction="column" alignItems="center">
                            <Grid item>
                                <Button onClick={handleClickOpenQR}>Click to Show QR Code</Button>
                            </Grid>
                        </Grid>
                        <Dialog
                            open={openQR}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleCloseQR}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogContent>
                                <QRCode
                                    size={356}
                                    style={{
                                        height: 'auto',
                                        maxWidth: '100%',
                                        width: '100%',
                                        marginTop: 10,
                                    }}
                                    value={`${window.location.host}/invite-organization/${organization.data?.uuid}`}
                                    viewBox="0 0 256 256"
                                />
                            </DialogContent>
                        </Dialog>
                    </Grid>
                </Box>
            </Paper>
            <Paper
                sx={{ margin: 6, background: 'linear-gradient(to bottom, white 30%, #EDF1F8 70%)' }}
            >
                <Box>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid container direction="column" alignItems="center">
                            <Grid item>
                                <Typography sx={{ mt: 10, mb: 10 }} textAlign="center" variant="h6">
                                    Brand Partner Policy and Pod Lease Agreements
                                </Typography>
                                <Typography
                                    sx={{ marginTop: 20, marginBottom: 20 }}
                                    variant="subtitle1"
                                >
                                    Below you will find the policies for Brand Partners,
                                    Organization Leases, and Athlete Leases
                                </Typography>
                                <Grid item textAlign="center">
                                    <Typography
                                        sx={{ marginTop: 20, marginBottom: 20 }}
                                        variant="subtitle2"
                                    >
                                        <a
                                            href="https://docs.google.com/document/d/1SUEM58W05ou92tIIB-TNmWJPI_Ail5IZAWCOGQG7ZwI/edit?usp=sharing"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Brand Partner Agreement
                                        </a>
                                    </Typography>
                                </Grid>
                                <Grid item textAlign="center">
                                    <Typography
                                        sx={{ marginTop: 20, marginBottom: 20 }}
                                        variant="subtitle2"
                                    >
                                        <a
                                            href="https://docs.google.com/document/d/19vs8lZqZkkotR4cAU9lRMY6QFyil7_mgiChMyIzVmcE/edit?usp=sharing"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Organization Pod Lease Agreement
                                        </a>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} textAlign="center">
                                    <Typography
                                        sx={{ marginTop: 20, marginBottom: 20 }}
                                        variant="subtitle2"
                                    >
                                        <a
                                            href="https://docs.google.com/document/d/1QjR9EqY6SxJe9snTI7g1hGdnC6OG8wc0qt0bXua5VR4/edit?usp=sharing"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Athlete Pod Lease Agreement
                                        </a>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
            <Paper
                sx={{ margin: 6, background: 'linear-gradient(to bottom, white 30%, #EDF1F8 70%)' }}
            >
                <Grid container direction="column" alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        <Typography sx={{ mt: 10, mb: 10 }} textAlign="center" variant="h6">
                            Marketing Resources
                        </Typography>
                        <Typography
                            sx={{ marginTop: 20, marginBottom: 20 }}
                            textAlign="center"
                            variant="subtitle1"
                        >
                            Below you will find the marketing resources and a link to our First
                            Annual 🔥
                            <strong> FiyrPod Summit </strong>🎉🚀
                        </Typography>
                    </Grid>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Grid
                            container
                            direction="row"
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{ display: 'flex', justifyContent: 'center' }}
                            >
                                <Card
                                    sx={{
                                        maxWidth: 345,
                                        width: '100%',
                                        boxShadow: 3,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        padding: 2,
                                        background:
                                            'linear-gradient(to bottom, black 30%, #EDF1F8 70%)',
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        image={Logo}
                                        alt="Logo"
                                        sx={{
                                            height: 140,
                                            width: 140,
                                            objectFit: 'contain',
                                            marginBottom: 2,
                                        }}
                                    />
                                    <CardContent
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant={'h6'}>Black Background</Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<DownloadIcon />}
                                            onClick={handleDownload}
                                            sx={{ marginTop: 2 }}
                                        >
                                            Save Logo
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                }}
                            >
                                <Card
                                    sx={{
                                        maxWidth: 345,
                                        width: '100%',
                                        boxShadow: 3,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        padding: 2,
                                        background:
                                            'linear-gradient(to bottom, white 30%, #EDF1F8 70%)',
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        image={LogoWB}
                                        alt="Logo"
                                        sx={{
                                            height: 140,
                                            width: 140,
                                            objectFit: 'contain',
                                            marginBottom: 2,
                                        }}
                                    />
                                    <CardContent
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant={'h6'}>White Background</Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<DownloadIcon />}
                                            onClick={handleDownload}
                                            sx={{ marginTop: 2 }}
                                        >
                                            Save Logo
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={11}>
                            <Card
                                sx={{
                                    maxWidth: 'auto',
                                    width: '100%',
                                    boxShadow: 3,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: { xs: 1, sm: 2 },
                                    marginTop: 6,
                                    background:
                                        'linear-gradient(to bottom, white 30%, #EDF1F8 70%)',
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    image={FoundersSummitPage1}
                                    alt="Logo"
                                    sx={{
                                        height: 'auto',
                                        width: '100%',
                                        objectFit: 'cover',
                                        marginBottom: 2,
                                    }}
                                />
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography variant={'h6'}>FiyrPod Summit</Typography>
                                    <Button
                                        variant="contained"
                                        startIcon={<HowToRegIcon />}
                                        onClick={() =>
                                            window.open(
                                                'https://www.eventbrite.com/e/fiyrpod-founders-summit-tickets-1043302454727?aff=oddtdtcreator',
                                                '_blank',
                                                'noopener,noreferrer',
                                            )
                                        }
                                        sx={{
                                            marginTop: 2,
                                            backgroundColor: '#05E354',
                                            '&:hover': {
                                                backgroundColor: '#04B843', // A slightly darker shade for hover effect
                                            },
                                        }}
                                    >
                                        Register Now
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={11}>
                            <Card
                                sx={{
                                    maxWidth: 'auto',
                                    width: '100%',
                                    boxShadow: 3,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: { xs: 1, sm: 2 },
                                    marginTop: 6,
                                    background:
                                        'linear-gradient(to bottom, white 30%, #EDF1F8 70%)',
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    image={FoundersSummitPage2}
                                    alt="Logo"
                                    sx={{
                                        height: 'auto',
                                        width: '100%',
                                        objectFit: 'cover',
                                        marginBottom: 2,
                                    }}
                                />
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        width: '100%',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography variant={'h6'}>FiyrPod Summit</Typography>
                                    <Button
                                        variant="contained"
                                        startIcon={<HowToRegIcon />}
                                        onClick={() =>
                                            window.open(
                                                'https://www.eventbrite.com/e/fiyrpod-founders-summit-tickets-1043302454727?aff=oddtdtcreator',
                                                '_blank',
                                                'noopener,noreferrer',
                                            )
                                        }
                                        sx={{
                                            marginTop: 2,
                                            backgroundColor: '#05E354',
                                            '&:hover': {
                                                backgroundColor: '#04B843', // A slightly darker shade for hover effect
                                            },
                                        }}
                                    >
                                        Register Now
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default PartnershipDashboardScene;
