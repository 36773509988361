import {
    Box,
    Button,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent,
    Snackbar,
    TextField,
    Grid,
    Typography,
    Alert,
} from '@mui/material';
import { DataGridPremium, GridSortModel } from '@mui/x-data-grid-premium';

import React, { useEffect, useState, useRef } from 'react';
import {
    getOrgSubscriptions,
    GetOrgSubscriptionParams,
    GetUserSubscriptionParams,
    getUserSubscriptions,
    changeOrganizationSubscriptionItemTier,
    cancelOrganizationSubscriptionItem,
    requestSubscriptionItemChange,
    getPendingSubscriptionItemChangeRequests,
} from '../networking.api';
import { OrgSubscription } from '../networking.types';
import { GridColDef } from '@mui/x-data-grid-premium';
import MenuItem from '@mui/material/MenuItem';
import { FactUserSubscription } from '../../admin/modules/subscriptions/subscriptions.types';
import { useParams } from 'react-router-dom';
import { SubscriptionItem, SubscriptionItemChangeRequest } from '../../../app.types';
import viewStripeHistory from '../../../api/Auth/viewStripeHistory';
import createBillingPortalSession from '../../../api/Auth/billingPortalSession';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ChooseOrganizationPlanPopover from '../../../components/Functional/ChooseOrganizationPlanPopover';
import { WarningAmber } from '@mui/icons-material';

const SubscriptionScene = () => {
    const [orgSubscriptions, setOrgSubscriptions] = useState<OrgSubscription[]>([]);
    const [hasActiveOrgSubscription, setHasActiveOrgSubscription] = useState<boolean>(false);

    const [orgSearchParams, setOrgSearchParams] = useState<GetOrgSubscriptionParams>({
        name: '',
        organization_uuid: '',
        level: '0',
        stripe_status: 'active',
        sort: '-created_at',
    });
    const [orgPaginationModel, setOrgPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [orgTotalRows, setOrgTotalRows] = useState(0);
    const [manageOrgSubscriptionItem, setManageOrgSubscriptionItem] =
        useState<SubscriptionItem | null>(null);
    const [managePodSubscriptionItem, setManagePodSubscriptionItem] =
        useState<SubscriptionItem | null>(null);
    const [manageFiyrlinkSubscriptionItem, setManageFiyrlinkSubscriptionItem] =
        useState<SubscriptionItem | null>(null);
    const orgSearchParamsRef = useRef(orgSearchParams);
    const { id } = useParams<{ id: string }>();
    const [selectedOrgSubscriptionValue, setSelectedOrgSubscriptionValue] = useState<string>('');
    const [snackMessage, setSnackMessage] = useState<string>('');
    const [userWantsToReturnPods, setUserWantsToReturnPods] = useState<boolean>(false);
    const [userWantsToCancelPods, setUserWantsToCancelPods] = useState<boolean>(false);
    const [userWantsToReturnFiyrlink, setUserWantsToReturnFiyrlink] = useState<boolean>(false);
    const [userWantsToCancelFiyrlink, setUserWantsToCancelFiyrlink] = useState<boolean>(false);
    const [selectedSubscriptionItem, setSelectedSubscriptionItem] =
        useState<SubscriptionItem | null>(null);
    const [pendingSubscriptionItemChangeRequests, setPendingSubscriptionItemChangeRequests] =
        useState<Array<SubscriptionItemChangeRequest>>([]);
    const [changeReason, setChangeReason] = useState<string>('');

    const handleSelectOrgSubscriptionChange = (event: SelectChangeEvent<string>) => {
        setSelectedOrgSubscriptionValue(event.target.value);
        if (manageOrgSubscriptionItem?.stripe_price === event.target.value) {
            return;
        }

        confirm('Are you sure you want to change your organization subscription?') &&
            changeOrganizationSubscriptionItemTier(
                event.target.value,
                id,
                manageOrgSubscriptionItem?.id as number,
            )
                .then(async (res) => {
                    if (res.status === 200) {
                        setSnackMessage('Organization subscription updated successfully');
                        setManageOrgSubscriptionItem(null);

                        // Refresh both the subscription data and pending change requests
                        try {
                            const [subscriptionResponse, changeRequestsResponse] =
                                await Promise.all([
                                    getOrgSubscriptions(id, {
                                        ...orgSearchParamsRef.current,
                                        per_page: orgPaginationModel.pageSize,
                                        page: orgPaginationModel.page + 1,
                                    }),
                                    getPendingSubscriptionItemChangeRequests(id),
                                ]);

                            setOrgSubscriptions(subscriptionResponse.data.data);
                            setOrgTotalRows(subscriptionResponse.data.meta.total);
                            setPendingSubscriptionItemChangeRequests(
                                changeRequestsResponse.data.data,
                            );
                        } catch (error) {
                            console.error(
                                'Error refreshing data after subscription change:',
                                error,
                            );
                            setSnackMessage(
                                'Subscription updated but unable to refresh display. Please refresh the page.',
                            );
                        }
                    } else {
                        setSnackMessage('Failed to update organization subscription');
                        setManageOrgSubscriptionItem(null);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setSnackMessage('Failed to update organization subscription');
                    setManageOrgSubscriptionItem(null);
                });
    };

    const openManageOrgSubscriptionItemModal = (item: SubscriptionItem) => {
        setManageOrgSubscriptionItem(item);
        setSelectedOrgSubscriptionValue(item.stripe_price);
    };

    const openManagePodSubscriptionItemModal = (item: SubscriptionItem) => {
        setManagePodSubscriptionItem(item);
    };

    const openManageFiyrlinkSubscriptionItemModal = (item: SubscriptionItem) => {
        setManageFiyrlinkSubscriptionItem(item);
    };

    const handleCancelOrgSubscription = () => {
        confirm('Are you sure you want to cancel your organization subscription?') &&
            cancelOrganizationSubscriptionItem(id, manageOrgSubscriptionItem?.id as number)
                .then((res) => {
                    if (res.status === 200) {
                        setSnackMessage('Organization subscription canceled successfully');
                        setManageOrgSubscriptionItem(null);
                        getOrgSubscriptionSearchResults(orgSearchParamsRef.current).then(() => {});
                    } else {
                        setSnackMessage('Failed to cancel organization subscription');
                        setManageOrgSubscriptionItem(null);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setSnackMessage('Failed to cancel organization subscription');
                    setManageOrgSubscriptionItem(null);
                });
    };

    const [userSubscriptions, setUserSubscriptions] = useState<FactUserSubscription[]>([]);
    const [userSearchParams, setUserSearchParams] = useState<GetUserSubscriptionParams>({
        name: 'active',
        user_name: '',
        level: '0',
        stripe_status: 'active',
        sort: '-fact_subscription.created_at',
    });
    const [userPaginationModel, setUserPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [userTotalRows, setUserTotalRows] = useState(0);
    const userSearchParamsRef = useRef(userSearchParams);

    const dataGridSx = {
        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
            outline: 'none',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
            {
                outline: 'none',
            },
    };

    useEffect(() => {
        orgSearchParamsRef.current = orgSearchParams;
        userSearchParamsRef.current = userSearchParams;
    }, [orgSearchParams, userSearchParams]);

    useEffect(() => {
        const params = {
            ...orgSearchParamsRef.current,
            per_page: orgPaginationModel.pageSize,
            page: orgPaginationModel.page + 1,
        };
        getOrgSubscriptions(id as string, params)
            .then((response) => {
                setOrgSubscriptions(response.data.data);
                setOrgTotalRows(response.data.meta.total);
                setHasActiveOrgSubscription(
                    response.data.data.some(
                        (subscription) =>
                            subscription.stripe_status === 'active' &&
                            (subscription.name === 'organization' ||
                                subscription.name === 'organization_combo'),
                    ),
                );
            })
            .catch((error) => {
                setSnackMessage(`Failed to fetch subscriptions ${error.response.data.message}`);
                console.error('Failed to fetch subscriptions', error);
            });
    }, [id, orgPaginationModel]);

    useEffect(() => {
        const params = {
            ...userSearchParamsRef.current,
            per_page: userPaginationModel.pageSize,
            page: userPaginationModel.page + 1,
        };
        getUserSubscriptions(id as string, params)
            .then((response) => {
                setUserSubscriptions(response.data.data);
                setUserTotalRows(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch subscriptions', error);
            });
    }, [id, userPaginationModel]);

    useEffect(() => {
        getPendingSubscriptionItemChangeRequests(id)
            .then((response) => {
                setPendingSubscriptionItemChangeRequests(response.data.data);
            })
            .catch((error) => {
                setSnackMessage(
                    `Failed to fetch pending subscription item change requests ${error.response.data.message}`,
                );
                console.error('Failed to fetch pending subscription item change requests', error);
            });
    }, [id]);

    const getFilteredColumns = (level: string, columns: GridColDef[]): GridColDef[] => {
        if (level === '0') {
            return columns;
        }
        return columns.filter((column) => column.field !== 'manage');
    };

    const orgColumns: GridColDef[] = [
        { field: 'name', headerName: 'Subscription Name', width: 250, sortable: true },
        {
            field: 'stripe_status',
            headerName: 'Status',
            width: 150,
            sortable: true,
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 150,
            sortable: true,
            renderCell: (params) => {
                return new Date(params.value as string).toLocaleDateString();
            },
        },
        {
            field: 'manage',
            headerName: 'Manage Subscription Items',
            width: 450,
            sortable: false,
            renderCell: (params) => {
                const { items, stripe_status } = params.row;

                if (!items || items.length === 0) {
                    return '';
                }

                // Add a key using row id and timestamp to force re-render
                const selectKey = `select-${params.row.id}-${params.row.updated_at}`;

                return (
                    <Select
                        key={selectKey}
                        variant="outlined"
                        style={{ width: '100%' }}
                        size="small"
                        value="" // Change from defaultValue to value
                        displayEmpty
                    >
                        <MenuItem value="" style={{ fontSize: '0.8rem' }}>
                            Select a subscription item
                        </MenuItem>
                        {items.map((item: SubscriptionItem) => (
                            <MenuItem
                                disabled={item.quantity === 0 || stripe_status === 'canceled'}
                                key={item.id}
                                value={item.name}
                                style={{ fontSize: '0.8rem' }}
                                onClick={() => {
                                    if (isOrgSubscription(item.stripe_price)) {
                                        openManageOrgSubscriptionItemModal(item);
                                    } else if (isPodSubscription(item.stripe_price)) {
                                        openManagePodSubscriptionItemModal(item);
                                    } else if (isFiyrlinkSubscription(item.stripe_price)) {
                                        openManageFiyrlinkSubscriptionItemModal(item);
                                    }
                                }}
                            >
                                {item.quantity > 0
                                    ? `Manage ${item.name} subscription [${item.quantity} item(s)]`
                                    : `${item.name} subscription (canceled)`}
                            </MenuItem>
                        ))}
                    </Select>
                );
            },
        },
    ];

    const userColumns: GridColDef[] = [
        { field: 'user_name', headerName: 'User Name', width: 150, sortable: false },
        { field: 'organization_name', headerName: 'Organization', width: 200, sortable: false },
        {
            field: 'name',
            headerName: 'Subscription Name',
            width: 200,
            sortable: false,
            // eslint-disable-next-line no-undef
            renderCell: (params: { row: { name: keyof typeof nameMap } }) => {
                const nameMap = {
                    default: '1Tul',
                    athlete_combo: 'Combo',
                    athlete_fiyrpod: 'Fiyrpod',
                    '': '',
                };
                return nameMap[params.row.name] ?? params.row.name;
            },
        },
        {
            field: 'stripe_status',
            headerName: 'Status',
            width: 150,
            sortable: false,
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 100,
            sortable: false,
        },
    ];

    const handleOrgSearchParameterChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setOrgSearchParams({
            ...orgSearchParams,
            [event.target.name as string]: event.target.value,
        });
    };

    const handleOrgSubscriptionSearch = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const params = {
                ...orgSearchParams,
                organization_uuid: orgSearchParams.organization_uuid || undefined,
                page: orgPaginationModel.page + 1,
                per_page: orgPaginationModel.pageSize,
            };
            await getOrgSubscriptionSearchResults(params);
        } catch (error) {
            console.error(error);
        }
    };

    const getOrgSubscriptionSearchResults = async (params: any) => {
        const paginatedParams = {
            ...params,
            page: orgPaginationModel.page + 1,
            per_page: orgPaginationModel.pageSize,
        };
        const response = await getOrgSubscriptions(id, paginatedParams);
        if (Array.isArray(response.data.data)) {
            setOrgSubscriptions(response.data.data);
            setOrgTotalRows(response.data.meta.total);
        } else {
            console.error('Unexpected data format: subscriptions data is not an array');
        }
    };

    const handleOrgSubscriptionSort = async (model: GridSortModel) => {
        let sortField = model[0]?.field;
        const sortOrder = model[0]?.sort;

        if (sortField && sortOrder === 'desc') {
            sortField = `-${sortField}`;
        }

        const params = {
            ...orgSearchParams,
            sort: sortField as GetOrgSubscriptionParams['sort'],
            page: orgPaginationModel.page + 1,
            per_page: orgPaginationModel.pageSize,
        };

        await getOrgSubscriptionSearchResults(params);
    };

    const handleOrgSelectStatusChange = (event: SelectChangeEvent<string>) => {
        setOrgSearchParams({
            ...orgSearchParams,
            stripe_status: event.target.value,
        });
    };

    const handleUserSelectSubscriptionNameChange = (event: SelectChangeEvent<string>) => {
        setUserSearchParams({
            ...userSearchParams,
            name: event.target.value,
        });
    };

    const handleOrgSelectLevelChange = (event: SelectChangeEvent<string>) => {
        setOrgSearchParams({
            ...orgSearchParams,
            level: event.target.value,
        });
    };

    const handleUserSearchParameterChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setUserSearchParams({
            ...userSearchParams,
            [event.target.name as string]: event.target.value,
        });
    };

    const handleUserSubscriptionSearch = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const params = {
                ...userSearchParams,
                user_name: userSearchParams.user_name || undefined,
                page: userPaginationModel.page + 1,
                per_page: userPaginationModel.pageSize,
            };
            await getUserSubscriptionSearchResults(params);
        } catch (error) {
            console.error(error);
        }
    };

    const getUserSubscriptionSearchResults = async (params: any) => {
        const paginatedParams = {
            ...params,
            page: userPaginationModel.page + 1,
            per_page: userPaginationModel.pageSize,
        };
        const response = await getUserSubscriptions(id, paginatedParams);
        if (Array.isArray(response.data.data)) {
            setUserSubscriptions(response.data.data);
            setUserTotalRows(response.data.meta.total);
        } else {
            console.error('Unexpected data format: subscriptions data is not an array');
        }
    };

    const handleUserSubscriptionSort = async (model: GridSortModel) => {
        let sortField = model[0]?.field;
        const sortOrder = model[0]?.sort;

        if (sortField && sortOrder === 'desc') {
            sortField = `-fact_subscription.${sortField}`;
        }

        const params = {
            ...userSearchParams,
            sort: sortField as GetUserSubscriptionParams['sort'],
            page: userPaginationModel.page + 1,
            per_page: userPaginationModel.pageSize,
        };

        await getUserSubscriptionSearchResults(params);
    };

    const handleUserSelectStatusChange = (event: SelectChangeEvent<string>) => {
        setUserSearchParams({
            ...userSearchParams,
            stripe_status: event.target.value,
        });
    };

    const handleUserSelectLevelChange = (event: SelectChangeEvent<string>) => {
        setUserSearchParams({
            ...userSearchParams,
            level: event.target.value,
        });
    };

    const isOrgSubscription = (itemStripePrice: string | undefined): boolean => {
        if (!itemStripePrice) {
            return false;
        }

        const level1Price = process.env.REACT_APP_STRIPE_ORGANIZATION_LEVEL_1_PRICE;
        const level2Price = process.env.REACT_APP_STRIPE_ORGANIZATION_LEVEL_2_PRICE;
        const level3Price = process.env.REACT_APP_STRIPE_ORGANIZATION_LEVEL_3_PRICE;

        return (
            itemStripePrice === level1Price ||
            itemStripePrice === level2Price ||
            itemStripePrice === level3Price
        );
    };

    const isPodSubscription = (itemStripePrice: string | undefined): boolean => {
        if (!itemStripePrice) {
            return false;
        }

        return itemStripePrice === process.env.REACT_APP_STRIPE_FIYRPOD_ORGANIZATION_PRICE;
    };

    const isFiyrlinkSubscription = (itemStripePrice: string | undefined): boolean => {
        if (!itemStripePrice) {
            return false;
        }

        const fiyrlinkPrices = [
            process.env.REACT_APP_STRIPE_FIYRLINK_PRICE,
            process.env.REACT_APP_STRIPE_FIYRLINK_FREE_PRICE,
        ];

        return fiyrlinkPrices.includes(itemStripePrice);
    };

    const returnOrCancelSubscriptionItems = async (requestType: string) => {
        requestSubscriptionItemChange(
            id,
            selectedSubscriptionItem?.id as number,
            requestType,
            changeReason,
        )
            .then((res) => {
                if (res.status === 200) {
                    setSnackMessage(
                        'Subscription item change request sent successfully! You will be contacted by an administrator.',
                    );
                    setChangeReason(''); // Reset reason after successful submission
                } else {
                    setSnackMessage('Failed to send subscription item change request.');
                }
            })
            .catch((error) => {
                console.error(error);
                setSnackMessage(
                    `Failed to send subscription item change request: ${error.response?.data?.message}`,
                );
            });
    };

    return (
        <>
            <Box style={{ marginLeft: '2rem' }}>
                <Snackbar
                    open={!!snackMessage}
                    autoHideDuration={6000}
                    message={snackMessage}
                    onClose={() => {
                        setSnackMessage('');
                    }}
                />
                <h2>Subscriptions</h2>
                {!hasActiveOrgSubscription && (
                    <Alert
                        severity="warning"
                        icon={false}
                        sx={{
                            width: '280px',
                            backgroundColor: 'rgba(255, 165, 0, 0.5)', // orangeyellow with 50% transparency
                            borderRadius: '8px',
                            padding: '8px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginBottom: '1rem',
                        }}
                    >
                        <Box display="flex" alignItems="center">
                            <WarningAmber sx={{ mr: 1 }} />
                            <Typography variant="h6" sx={{ color: 'black', fontSize: '0.8rem' }}>
                                Organization subscription required
                            </Typography>
                        </Box>
                        <Box display="block" sx={{ mt: 2 }}>
                            <ChooseOrganizationPlanPopover
                                organization={id}
                                buttonText="Purchase Subscription"
                            />
                        </Box>
                    </Alert>
                )}
                <Button
                    variant={'outlined'}
                    style={{ fontSize: '0.7rem', marginRight: '4px' }}
                    onClick={() => {
                        viewStripeHistory(window.location.href, id)
                            .then((response) => {
                                window.location.href = response.data.url;
                            })
                            .catch(() => {});
                    }}
                    size="small"
                >
                    My Billing History
                </Button>
                <Button
                    variant={'outlined'}
                    style={{ fontSize: '0.7rem' }}
                    onClick={() => {
                        createBillingPortalSession(
                            window.location.href,
                            id,
                            'subscription_change_card',
                        )
                            .then((response) => {
                                window.location.href = response.data.url;
                            })
                            .catch(() => {
                                console.error('Failed to create billing portal session');
                            });
                    }}
                    size="small"
                >
                    Change Card
                </Button>
                {pendingSubscriptionItemChangeRequests.length > 0 && (
                    <section>
                        <h5>Subscription Item Change Requests</h5>
                        <Box
                            width="33%"
                            maxHeight="300px"
                            overflow="auto"
                            className="dataGridContainer"
                        >
                            <DataGridPremium
                                rows={pendingSubscriptionItemChangeRequests}
                                columns={[
                                    {
                                        field: 'subscriptionName',
                                        headerName: 'Subscription Item',
                                        width: 200,
                                        renderCell: (params) => {
                                            return params.row.subscription_item?.name;
                                        },
                                    },
                                    {
                                        field: 'change_request_type',
                                        headerName: 'Request Type',
                                        width: 150,
                                    },
                                    { field: 'status', headerName: 'Status', width: 150 },
                                    {
                                        field: 'created_at',
                                        headerName: 'Requested At',
                                        width: 150,
                                        renderCell: (params) => {
                                            const date = new Date(params.row.created_at * 1000);
                                            return `${
                                                date.getMonth() + 1
                                            }/${date.getDate()}/${date.getFullYear()}`;
                                        },
                                    },
                                ]}
                                checkboxSelection={false}
                                sortingMode="server"
                                style={{ height: '162px', marginBottom: '50px' }}
                                paginationMode="server"
                                getRowId={(row) => row.uuid}
                                sx={dataGridSx}
                                disableColumnMenu={true}
                                hideFooter={true}
                            />
                        </Box>
                    </section>
                )}
                <Grid container spacing={0}>
                    <Grid item md={12} lg={6}>
                        <section style={{ marginBottom: '2rem' }}>
                            <h4>Filter Organization Subscriptions</h4>
                            <form onSubmit={handleOrgSubscriptionSearch} noValidate>
                                <FormControl>
                                    <TextField
                                        name="name"
                                        label="Subcription Name"
                                        value={orgSearchParams.name}
                                        onChange={handleOrgSearchParameterChange}
                                        placeholder="Enter Subscription Name"
                                        size={'small'}
                                        sx={{ marginRight: '2px' }}
                                    />
                                </FormControl>
                                <FormControl sx={{ marginRight: '4px' }}>
                                    <InputLabel id="status-label">Status</InputLabel>
                                    <Select
                                        variant="outlined"
                                        size="small"
                                        value={orgSearchParams.stripe_status}
                                        onChange={handleOrgSelectStatusChange}
                                        label="Status"
                                    >
                                        <MenuItem value="active">Active</MenuItem>
                                        <MenuItem value="incomplete">Incomplete</MenuItem>
                                        <MenuItem value="past_due">Past Due</MenuItem>
                                        <MenuItem value="canceled">Canceled</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ marginRight: '4px' }}>
                                    <InputLabel id="status-label">Level</InputLabel>
                                    <Select
                                        variant="outlined"
                                        size="small"
                                        value={orgSearchParams.level}
                                        onChange={handleOrgSelectLevelChange}
                                        label="Level"
                                    >
                                        <MenuItem value="0">My Subscriptions</MenuItem>
                                        <MenuItem value="1">Level 1 Partners</MenuItem>
                                    </Select>
                                </FormControl>
                                <Button type="submit" size={'medium'} variant="contained">
                                    Search
                                </Button>
                            </form>
                        </section>
                        <Box
                            width="95%"
                            maxHeight="624px"
                            overflow="auto"
                            className="dataGridContainer"
                        >
                            <DataGridPremium
                                rows={orgSubscriptions}
                                columns={getFilteredColumns(orgSearchParams.level, orgColumns)}
                                checkboxSelection={false}
                                sortingMode="server"
                                onSortModelChange={(model) => handleOrgSubscriptionSort(model)}
                                getRowId={(row) => row.id}
                                style={{ height: '624px', marginBottom: '50px' }}
                                paginationMode="server"
                                pagination
                                rowCount={orgTotalRows}
                                paginationModel={orgPaginationModel}
                                onPaginationModelChange={setOrgPaginationModel}
                                pageSizeOptions={[5, 10, 25, 50, 100]}
                                sx={dataGridSx}
                                disableColumnMenu={true}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={12} lg={6}>
                        <section style={{ marginBottom: '2rem' }}>
                            <h4>Filter User Subscriptions</h4>
                            <form onSubmit={handleUserSubscriptionSearch} noValidate>
                                <FormControl>
                                    <TextField
                                        name="user_name"
                                        label="User Name"
                                        value={userSearchParams.user_name}
                                        onChange={handleUserSearchParameterChange}
                                        placeholder="Enter User Name"
                                        size={'small'}
                                        sx={{ marginRight: '2px' }}
                                    />
                                </FormControl>
                                <FormControl sx={{ marginRight: '4px', width: '200px' }}>
                                    <InputLabel id="status-label">Subscription</InputLabel>
                                    <Select
                                        variant="outlined"
                                        size="small"
                                        value={userSearchParams.name}
                                        onChange={handleUserSelectSubscriptionNameChange}
                                        label="Subscription"
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        <MenuItem value="active">1Tul or Combo</MenuItem>
                                        <MenuItem value="default">1Tul</MenuItem>
                                        <MenuItem value="athlete_combo">Combo</MenuItem>
                                        <MenuItem value="athlete_fiyrpod">Fiyrpod</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ marginRight: '4px' }}>
                                    <InputLabel id="status-label">Status</InputLabel>
                                    <Select
                                        variant="outlined"
                                        size="small"
                                        value={userSearchParams.stripe_status}
                                        onChange={handleUserSelectStatusChange}
                                        label="Status"
                                    >
                                        <MenuItem value="active">Active</MenuItem>
                                        <MenuItem value="incomplete">Incomplete</MenuItem>
                                        <MenuItem value="past_due">Past Due</MenuItem>
                                        <MenuItem value="canceled">Canceled</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ marginRight: '4px' }}>
                                    <InputLabel id="status-label">Level</InputLabel>
                                    <Select
                                        variant="outlined"
                                        size="small"
                                        value={userSearchParams.level}
                                        onChange={handleUserSelectLevelChange}
                                        label="Level"
                                    >
                                        <MenuItem value="0">My User Subscriptions</MenuItem>
                                        <MenuItem value="1">Level 1 Partners</MenuItem>
                                    </Select>
                                </FormControl>
                                <Button type="submit" size={'medium'} variant="contained">
                                    Search
                                </Button>
                            </form>
                        </section>
                        <Box
                            width="95%"
                            maxHeight="624px"
                            overflow="auto"
                            className="dataGridContainer"
                        >
                            <DataGridPremium
                                rows={userSubscriptions}
                                columns={userColumns}
                                checkboxSelection={false}
                                sortingMode="server"
                                onSortModelChange={(model) => handleUserSubscriptionSort(model)}
                                getRowId={(row) => row.id}
                                style={{ height: '624px', marginBottom: '50px' }}
                                paginationMode="server"
                                pagination
                                rowCount={userTotalRows}
                                paginationModel={userPaginationModel}
                                onPaginationModelChange={setUserPaginationModel}
                                pageSizeOptions={[5, 10, 25, 50, 100]}
                                sx={dataGridSx}
                                disableColumnMenu={true}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Dialog
                    open={userWantsToReturnPods}
                    onClose={() => {
                        setUserWantsToReturnPods(false);
                        setChangeReason('');
                    }}
                >
                    <DialogTitle>Return pods</DialogTitle>
                    <DialogContent>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label="Reason for return"
                                value={changeReason}
                                onChange={(e) => setChangeReason(e.target.value)}
                                multiline
                                rows={3}
                                required
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            To return pod(s) please provide a reason and click below. An
                            administrator will respond to your request.
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant={'contained'}
                            onClick={() => {
                                setUserWantsToReturnPods(false);
                                returnOrCancelSubscriptionItems('return_items').then(() => {
                                    setChangeReason('');
                                });
                            }}
                            disabled={!changeReason.trim()}
                        >
                            Return pods
                        </Button>
                        <Button
                            size="small"
                            variant={'contained'}
                            onClick={() => {
                                setUserWantsToReturnPods(false);
                                setChangeReason('');
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={userWantsToCancelPods}
                    onClose={() => {
                        setUserWantsToCancelPods(false);
                        setChangeReason('');
                    }}
                >
                    <DialogTitle>Cancel pod subscription item</DialogTitle>
                    <DialogContent>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label="Reason for cancellation"
                                value={changeReason}
                                onChange={(e) => setChangeReason(e.target.value)}
                                multiline
                                rows={3}
                                required
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            To cancel your Fiyrpod subscription please provide a reason and click
                            below. An administrator will respond to your request.
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant={'contained'}
                            onClick={() => {
                                setUserWantsToCancelPods(false);
                                returnOrCancelSubscriptionItems('cancel').then(() => {
                                    setChangeReason('');
                                });
                            }}
                            disabled={!changeReason.trim()}
                        >
                            Cancel subscription
                        </Button>
                        <Button
                            size="small"
                            variant={'contained'}
                            onClick={() => {
                                setUserWantsToCancelPods(false);
                                setChangeReason('');
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={userWantsToReturnFiyrlink}
                    onClose={() => {
                        setUserWantsToReturnFiyrlink(false);
                        setChangeReason('');
                    }}
                >
                    <DialogTitle>Return Fiyrlink Gateway(s)</DialogTitle>
                    <DialogContent>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label="Reason for return"
                                value={changeReason}
                                onChange={(e) => setChangeReason(e.target.value)}
                                multiline
                                rows={3}
                                required
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            To return your Fiyrlink Gateway(s) please provide a reason and click
                            below. An administrator will respond to your request.
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant={'contained'}
                            onClick={() => {
                                setUserWantsToReturnFiyrlink(false);
                                returnOrCancelSubscriptionItems('return_items').then(() => {
                                    setChangeReason('');
                                });
                            }}
                            disabled={!changeReason.trim()}
                        >
                            Request return item(s)
                        </Button>
                        <Button
                            size="small"
                            variant={'contained'}
                            onClick={() => {
                                setUserWantsToReturnFiyrlink(false);
                                setChangeReason('');
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={userWantsToCancelFiyrlink}
                    onClose={() => {
                        setUserWantsToCancelFiyrlink(false);
                        setChangeReason('');
                    }}
                >
                    <DialogTitle>Cancel Fiyrlink subscription item</DialogTitle>
                    <DialogContent>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label="Reason for cancellation"
                                value={changeReason}
                                onChange={(e) => setChangeReason(e.target.value)}
                                multiline
                                rows={3}
                                required
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            To cancel your Fiyrlink subscription please provide a reason and click
                            below. An administrator will respond to your request.
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant={'contained'}
                            onClick={() => {
                                setUserWantsToReturnFiyrlink(false);
                                returnOrCancelSubscriptionItems('cancel').then(() => {
                                    setChangeReason('');
                                });
                            }}
                            disabled={!changeReason.trim()}
                        >
                            Cancel subscription
                        </Button>
                        <Button
                            size="small"
                            variant={'contained'}
                            onClick={() => {
                                setUserWantsToCancelFiyrlink(false);
                                setChangeReason('');
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={!!managePodSubscriptionItem}
                    onClose={() => {
                        setManagePodSubscriptionItem(null);
                    }}
                >
                    <DialogTitle>Manage Fiyrpod Subscription Item</DialogTitle>
                    <DialogContent>
                        You may change quantities or cancel your subscription item here.
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant={'contained'}
                            onClick={() => {
                                setSelectedSubscriptionItem(managePodSubscriptionItem);
                                setManagePodSubscriptionItem(null);
                                setUserWantsToReturnPods(true);
                            }}
                        >
                            Return unused pods
                        </Button>
                        <Button
                            size="small"
                            variant={'contained'}
                            onClick={() => {
                                setSelectedSubscriptionItem(managePodSubscriptionItem);
                                setManagePodSubscriptionItem(null);
                                setUserWantsToCancelPods(true);
                            }}
                        >
                            Cancel Subscription
                        </Button>
                        <Button
                            size="small"
                            variant={'contained'}
                            onClick={() => {
                                setManagePodSubscriptionItem(null);
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={!!manageFiyrlinkSubscriptionItem}
                    onClose={() => {
                        setManageFiyrlinkSubscriptionItem(null);
                    }}
                >
                    <DialogTitle>Manage Fiyrlink Subscription Item</DialogTitle>
                    <DialogContent>
                        You may change quantities or cancel your subscription item here.
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                setSelectedSubscriptionItem(manageFiyrlinkSubscriptionItem);
                                setUserWantsToReturnFiyrlink(true);
                                setManageFiyrlinkSubscriptionItem(null);
                            }}
                        >
                            Return unused Fiyrlink gateways
                        </Button>
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                setSelectedSubscriptionItem(manageFiyrlinkSubscriptionItem);
                                setUserWantsToCancelFiyrlink(true);
                                setManageFiyrlinkSubscriptionItem(null);
                            }}
                        >
                            Cancel subscription
                        </Button>
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                setManageFiyrlinkSubscriptionItem(null);
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={!!manageOrgSubscriptionItem}
                    onClose={() => {
                        setManageOrgSubscriptionItem(null);
                    }}
                >
                    <DialogTitle>Manage Organization Subscription Item</DialogTitle>
                    <DialogContent>
                        You may change or cancel your organization subscription item here.
                    </DialogContent>
                    <DialogActions>
                        <InputLabel id="status-label">Change subscription Level</InputLabel>
                        <Select
                            size="small"
                            variant="outlined"
                            value={selectedOrgSubscriptionValue}
                            onChange={handleSelectOrgSubscriptionChange}
                        >
                            <MenuItem
                                value={process.env.REACT_APP_STRIPE_ORGANIZATION_LEVEL_1_PRICE}
                                selected={
                                    manageOrgSubscriptionItem?.stripe_price ===
                                    process.env.REACT_APP_STRIPE_ORGANIZATION_LEVEL_1_PRICE
                                }
                                disabled={
                                    manageOrgSubscriptionItem?.stripe_price ===
                                    process.env.REACT_APP_STRIPE_ORGANIZATION_LEVEL_1_PRICE
                                }
                            >
                                Bronze
                            </MenuItem>
                            <MenuItem
                                value={process.env.REACT_APP_STRIPE_ORGANIZATION_LEVEL_2_PRICE}
                                selected={
                                    manageOrgSubscriptionItem?.stripe_price ===
                                    process.env.REACT_APP_STRIPE_ORGANIZATION_LEVEL_2_PRICE
                                }
                                disabled={
                                    manageOrgSubscriptionItem?.stripe_price ===
                                    process.env.REACT_APP_STRIPE_ORGANIZATION_LEVEL_2_PRICE
                                }
                            >
                                Silver
                            </MenuItem>
                            <MenuItem
                                value={process.env.REACT_APP_STRIPE_ORGANIZATION_LEVEL_3_PRICE}
                                selected={
                                    manageOrgSubscriptionItem?.stripe_price ===
                                    process.env.REACT_APP_STRIPE_ORGANIZATION_LEVEL_3_PRICE
                                }
                                disabled={
                                    manageOrgSubscriptionItem?.stripe_price ===
                                    process.env.REACT_APP_STRIPE_ORGANIZATION_LEVEL_3_PRICE
                                }
                            >
                                Gold
                            </MenuItem>
                        </Select>
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                handleCancelOrgSubscription();
                            }}
                        >
                            Cancel Subscription
                        </Button>
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                setManageOrgSubscriptionItem(null);
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    );
};

export default SubscriptionScene;
