import { getAllMovements, getAllOrganizations, getAllOrganizationSubscriptions } from 'api';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from 'redux/reducers/auth';
import { Movement, Organization } from 'utils';
import { Subscription } from '../app.types';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { Button, FormControl, InputLabel, MenuItem, Select, Snackbar } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import organizationUpdatePaymentMethod from '../api/Auth/organizationUpdatePaymentMethod';
import { getFromLocalStorageWithExpiry, setInLocalStorageWithExpiry } from '../utils/localStorage';

interface Props {
    organizations: Organization[];
    subscriptions: Subscription[];
    movements?: Movement[];
    loading?: boolean;
    loaded?: boolean;
}

export const OrganizationsContext = React.createContext<Props>({
    organizations: [],
    subscriptions: [],
    movements: [],
    loading: true,
    loaded: false,
});

export function OrganizationsProvider({ children }: { children: React.ReactNode }) {
    const { isAuth, organizationsLoadKey } = useSelector(authSelector);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const [uniqueOrgPastDueSubscriptions, setUniqueOrgPastDueSubscriptions] = useState<
        Subscription[]
    >([]);
    const [selectedOrgUuidToChangePaymentMethod, setSelectedOrgUuidToChangePaymentMethod] =
        useState<string>('');
    const [movements, setMovements] = useState<Movement[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [isPastDueOrgSubscriptionModalOpen, setIsPastDueOrgSubscriptionModalOpen] =
        useState(false);
    const [
        isPastDueOrgSubscriptionModalClosedInSession,
        setIsPastDueOrgSubscriptionModalClosedInSession,
    ] = useState(false);
    const [snackMessage, setSnackMessage] = useState<string>('');
    // const [orgsWithPastDueInvoices, setOrgsWithPastDueInvoices] = useState<OrgWithPastDueInvoice[]>(
    //     [],
    // );
    // const [isPastDueInvoicesModalOpen, setIsPastDueInvoicesModalOpen] = useState(false);
    //
    // const closePastDueInvoicesModal = () => {
    //     setIsPastDueInvoicesModalOpen(false);
    // };

    // const PastDueInvoicesModal = () => {
    //     const messageStart =
    //         orgsWithPastDueInvoices.length === 1
    //             ? 'You have past due invoices for the organization:'
    //             : 'You have past due invoices for organizations:';
    //
    //     const organizationLinks = orgsWithPastDueInvoices.map((org: OrgWithPastDueInvoice) => (
    //         <span key={org.uuid}>
    //             <a
    //                 href={`/organizations/${org.uuid}`}
    //                 target="_blank"
    //                 rel="noopener noreferrer"
    //                 className="text-blue-600 hover:text-blue-800"
    //             >
    //                 {org.name}
    //             </a>
    //         </span>
    //     ));
    //
    //     return (
    //         <Dialog open={isPastDueInvoicesModalOpen} onClose={closePastDueInvoicesModal}>
    //             <DialogTitle>Past Due Invoices</DialogTitle>
    //             <Divider></Divider>
    //             <DialogContent>
    //                 <DialogContentText>
    //                     {messageStart}{' '}
    //                     {orgsWithPastDueInvoices.length === 1 ? (
    //                         organizationLinks
    //                     ) : (
    //                         <>
    //                             {organizationLinks.slice(0, -1).map((link, index) => (
    //                                 <React.Fragment key={index}>
    //                                     {link}
    //                                     {index < organizationLinks.length - 2 && ', '}
    //                                 </React.Fragment>
    //                             ))}
    //                             {' and '}
    //                             {organizationLinks[organizationLinks.length - 1]}
    //                         </>
    //                     )}
    //                     <br />
    //                     <br />
    //                     Please visit the invoices section at the link(s) above to rectify this
    //                     issue.
    //                 </DialogContentText>
    //             </DialogContent>
    //             <Divider></Divider>
    //             <DialogActions>
    //                 <Button variant="contained" onClick={closePastDueInvoicesModal}>
    //                     OK
    //                 </Button>
    //             </DialogActions>
    //         </Dialog>
    //     );
    // };

    const handleChangeBillingMethod = () => {
        setInLocalStorageWithExpiry('organizationUpdatePaymentMethodAcknowledged', true, 24);
        organizationUpdatePaymentMethod(selectedOrgUuidToChangePaymentMethod, window.location.href)
            .then((response) => {
                closePastDueOrgSubscriptionModal();
                window.location.href = response.data.url;
            })
            .catch((e: any) => {
                setSnackMessage(`Error creating checkout session. ${e.response?.data?.message}`);
            });
    };

    const closePastDueOrgSubscriptionModal = () => {
        setInLocalStorageWithExpiry('organizationUpdatePaymentMethodAcknowledged', true, 24);
        setIsPastDueOrgSubscriptionModalOpen(false);
        setIsPastDueOrgSubscriptionModalClosedInSession(true);
    };

    useEffect(() => {
        if (!isAuth) {
            setLoading(false);
            setOrganizations([]);
            setMovements([]);
            return;
        }
        setLoading(true);
        Promise.all([getAllOrganizations(), getAllOrganizationSubscriptions(), getAllMovements()])
            .then(([response, subscriptionResponse, movementResponse]) => {
                setOrganizations(response.data);
                setSubscriptions(subscriptionResponse.data);
                if (Array.isArray(movementResponse.data) && movementResponse.data.length > 0) {
                    setMovements([movementResponse.data[0]]);
                }
                setLoaded(true);
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    }, [isAuth, organizationsLoadKey]);

    useEffect(() => {
        if (loaded) {
            const uniquePastDueSubscriptions: Subscription[] = [];
            const uniqueOrganizations = new Set();

            subscriptions.forEach((subscription) => {
                if (
                    subscription.stripe_status === 'past_due' &&
                    !uniqueOrganizations.has(subscription.organization_uuid)
                ) {
                    uniqueOrganizations.add(subscription.organization_uuid);
                    uniquePastDueSubscriptions.push(subscription);
                }
            });

            if (
                uniquePastDueSubscriptions.length &&
                !isPastDueOrgSubscriptionModalClosedInSession &&
                getFromLocalStorageWithExpiry('organizationUpdatePaymentMethodAcknowledged') !==
                    true
            ) {
                console.debug('Past due subscription(s) found');
                setIsPastDueOrgSubscriptionModalOpen(true);
                setUniqueOrgPastDueSubscriptions(uniquePastDueSubscriptions);
                setSelectedOrgUuidToChangePaymentMethod(
                    uniquePastDueSubscriptions[0].organization_uuid,
                );
            }
        }
    }, [isPastDueOrgSubscriptionModalClosedInSession, loaded, subscriptions]);

    const ModalComponent = () => (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={!!snackMessage}
                autoHideDuration={6000}
                message={snackMessage}
                onClose={() => setSnackMessage('')}
            />
            <Dialog
                open={isPastDueOrgSubscriptionModalOpen}
                onClose={closePastDueOrgSubscriptionModal}
            >
                <DialogTitle>Organization Subscription Payment Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        One or more of your organization subscriptions are past due. Please update
                        your payment information on Stripe as soon as possible to avoid any service
                        interruptions.
                    </DialogContentText>
                    <FormControl
                        size="small"
                        style={{
                            width: '50%',
                            margin: '10px auto 0 auto',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <InputLabel id="organization-label">Organization Subscription</InputLabel>
                        <Select
                            labelId="organization-label"
                            label="Organization Subscription"
                            value={selectedOrgUuidToChangePaymentMethod}
                            onChange={(event: any) => {
                                setSelectedOrgUuidToChangePaymentMethod(
                                    event.target.value as string,
                                );
                            }}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            {uniqueOrgPastDueSubscriptions.map((subscription) => (
                                <MenuItem
                                    key={subscription.organization_uuid}
                                    value={subscription.organization_uuid}
                                >
                                    {subscription.organization_name} ({subscription.name})
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleChangeBillingMethod}>
                        Update Payment Information
                    </Button>
                    <Button variant="outlined" onClick={closePastDueOrgSubscriptionModal}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

    return (
        <OrganizationsContext.Provider
            value={{ organizations, subscriptions, movements, loading, loaded }}
        >
            {children}
            <ModalComponent />
        </OrganizationsContext.Provider>
    );
}
