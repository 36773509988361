import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Drawer,
    FormControl,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { DateTime } from 'luxon';
import React, { useRef, useState } from 'react';
import SprintLogo from '../../../../src/images/1080SprintPNG.jpeg';
import PlayerTekLogo from '../../../../src/images/CatapultPlayerTek.png';
import fiyrPodLogo from '../../../../src/images/fiyrPodLogo.png';
import { Tag } from '../../../app.types';
import {
    SelectDateChip,
    TypographyBodyOne,
    TypographyBodyTwo,
    TypographyTitleh6,
} from '../../../components';
import { CurrentUser } from '../../../redux/reducers/auth';
import { Individual } from '../../../utils';
import S3Upload from '../../../utils/S3Upload';
import { uploadPerformanceData } from '../api/performance.api';
import { SprintDataType } from '../community.types';
import FreeSoloCreateOptionDialog, {
    FreeSoloOption,
} from '../components/FreeSoloCreateOptionDialog';

interface Props {
    individual: Individual;
    currentUser: CurrentUser;
    onUploaded: () => void;
    onClose: () => void;
    open: boolean;
    tags: Array<Tag>;
    onTagCreated: (tag: Tag) => void;
}

function PerformanceDataUploadDrawer({
    individual,
    onUploaded,
    currentUser,
    open,
    onClose,
    tags,
    onTagCreated,
}: Props) {
    const [tag, setTag] = React.useState<FreeSoloOption | null>(null);
    const [dataType, setDataType] = useState<SprintDataType>('mclloyd');
    const [height, setHeight] = useState<number>(72);
    const [weight, setWeight] = useState<number>(180);
    const [minVelocity, setMinVelocity] = useState<number>(3.57);
    const [saving, setSaving] = useState(false);
    const [progress, setProgress] = useState(0);
    const [fileUploading, setFileUploading] = useState(false);
    const [originalFileName, setOriginalFileName] = useState('');
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [uploadedFilePath, setUploadedFilePath] = useState('');
    const inputElement = useRef<HTMLInputElement | null>(null);
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [date, setDate] = useState<DateTime>(DateTime.now());
    const gradientAnimation = `@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}`;
    const uploadToS3 = (csvFile: File) => {
        setOriginalFileName(csvFile.name);

        const uploader = new S3Upload();

        uploader.onStarted = () => {
            setFileUploading(true);
        };

        uploader.onBeforeSigner = (fileName, xhr) => {
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', `Bearer ${currentUser?.accessToken}`);
        };

        uploader.onComplete = (fileName, awsObjectKey) => {
            setFileUploading(false);
            setUploadedFileName(fileName);
            setUploadedFilePath(awsObjectKey);
            setProgress(0);
            if (inputElement.current) {
                inputElement.current.value = '';
                inputElement.current.files = null;
            }
        };

        uploader.onError = () => {
            setFileUploading(false);
            setOriginalFileName('Error. Try Again');
            if (inputElement.current) {
                inputElement.current.value = '';
                inputElement.current.files = null;
            }
        };

        uploader.onProgress = (fileName, originalFileName, number) => {
            setProgress(number);
        };

        uploader.uploadToS3(csvFile as File);
    };

    function save() {
        setSaving(true);
        uploadPerformanceData(
            individual.uuid,
            originalFileName,
            uploadedFilePath,
            dataType,
            date.toFormat('yyyy-MM-dd'),
            weight,
            height,
            minVelocity,
            tag ? tag.id : '',
        )
            .then(() => {
                onUploaded();
            })
            .catch(() => {})
            .finally(() => {
                setSaving(false);
                onClose();
            });
    }

    return (
        <Drawer
            PaperProps={{ style: { width: isMobile ? '100%' : '60%' } }}
            anchor={'right'}
            open={open}
            onBackdropClick={() => onClose()}
        >
            {fileUploading && <LinearProgress variant={'determinate'} value={progress} />}
            <Box display="flex" flexDirection="column">
                <Box
                    display="flex"
                    alignItems={'center'}
                    justifyContent="space-between"
                    style={{ borderBottom: '1px solid grey', padding: 20 }}
                >
                    <Box>
                        <TypographyTitleh6
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            Upload Sprint Data
                        </TypographyTitleh6>
                    </Box>
                    <IconButton onClick={() => onClose()} aria-label="close" size="medium">
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </Box>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'flex-start'}
                    p={8}
                    borderBottom={'1px solid grey'}
                >
                    <Box>
                        <FormControl variant="filled" style={{ marginBottom: 8, minWidth: 340 }}>
                            <InputLabel>System</InputLabel>
                            <Select
                                value={dataType}
                                onChange={(event) =>
                                    setDataType(event.target.value as SprintDataType)
                                }
                                id="demo-simple-select"
                                name="organization"
                            >
                                <MenuItem value={'mclloyd'}>
                                    <Typography
                                        variant="subtitle1"
                                        color="text.primary"
                                        sx={{ marginRight: 16 }}
                                    >
                                        STV4
                                    </Typography>
                                    <img src={fiyrPodLogo} height="40" alt={'STV4'} />
                                </MenuItem>
                                <MenuItem value={'mclloyd-ATV1'}>
                                    <Typography
                                        variant="subtitle1"
                                        color="text.primary"
                                        sx={{ marginRight: 16 }}
                                    >
                                        ATV1
                                    </Typography>
                                    <img src={fiyrPodLogo} height="40" alt={'ATV1'} />
                                </MenuItem>
                                <MenuItem value={'1080'}>
                                    <Typography
                                        variant="subtitle1"
                                        color="text.primary"
                                        sx={{ marginRight: 10 }}
                                    >
                                        1080 Sprint
                                    </Typography>
                                    <img src={SprintLogo} height="40" alt={'PlayerTekLogo'} />
                                </MenuItem>
                                <MenuItem value={'catapultPlayerTek'}>
                                    <Typography
                                        variant="subtitle1"
                                        color="text.primary"
                                        sx={{ marginRight: 10 }}
                                    >
                                        Catapult PlayerTek
                                    </Typography>
                                    <img src={PlayerTekLogo} height="40" alt={'PlayerTeklogo'} />
                                </MenuItem>
                            </Select>
                        </FormControl>
                        {dataType === 'mclloyd' && (
                            <Box sx={{ width: '100%' }}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography sx={{ fontSize: theme.typography.pxToRem(15) }}>
                                            McLLOYD STv4 Format
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>time_recept</th>
                                                    <th>speed_1</th>
                                                    <th>speed_2</th>
                                                    <th>speed_3</th>
                                                    <th>speed_4</th>
                                                    <th>...</th>
                                                    <th>speed_10</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>0.92</td>
                                                    <td>0.92</td>
                                                    <td>0.92</td>
                                                    <td>1.00</td>
                                                    <td>1.10</td>
                                                    <td>...</td>
                                                    <td>1.22</td>
                                                </tr>
                                                <tr>
                                                    <td>1.22</td>
                                                    <td>1.23</td>
                                                    <td>1.5</td>
                                                    <td>1.8</td>
                                                    <td>0.85</td>
                                                    <td>...</td>
                                                    <td>5.22</td>
                                                </tr>
                                                <tr>
                                                    <td>1.22</td>
                                                    <td>1.23</td>
                                                    <td>1.5</td>
                                                    <td>1.8</td>
                                                    <td>0.85</td>
                                                    <td>...</td>
                                                    <td>5.22</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Typography>
                                            For McLoyd we require the timestamp and speed_x columns
                                            from the export. Format your data as shown in the
                                            example above otherwise it will fail.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        )}
                        {dataType === '1080' && (
                            <Box sx={{ width: '100%' }}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography sx={{ fontSize: theme.typography.pxToRem(15) }}>
                                            1080 Motion CSV Format
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Set Date & Time [ISO 8601]</th>
                                                    <th>Set #</th>
                                                    <th>Repetition # (per Set)</th>
                                                    <th>Concentric/Resisted load [kg]</th>
                                                    <th>Eccentric/Assisted load [kg]</th>
                                                    <th>Speed [m/s]</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>2022-10-26T12:30:42.9800000+00:00</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>13</td>
                                                    <td>0.4423</td>
                                                </tr>
                                                <tr>
                                                    <td>2022-10-26T12:30:42.9800000+00:00</td>
                                                    <td>1</td>
                                                    <td>2</td>
                                                    <td>1</td>
                                                    <td>13</td>
                                                    <td>0.4423</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Typography>
                                            Make sure your 1080 Motion CSV file is formatted as
                                            shown. You can have extra columns but the above are
                                            required.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        )}
                    </Box>
                    <Box
                        sx={{
                            paddingY: 8,
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        <div>
                            <TypographyBodyOne>Create or Choose Category</TypographyBodyOne>
                        </div>

                        <FreeSoloCreateOptionDialog
                            createProps={{ type: 'uploads' }}
                            value={tag}
                            onChange={(v) => setTag(v)}
                            organization={individual.organization.uuid}
                            choices={tags.map((t) => ({ id: t.id, title: t.name }))}
                            onTagCreated={onTagCreated}
                        />
                    </Box>

                    <Box
                        paddingY={8}
                        alignItems={'center'}
                        width={'100%'}
                        display="flex"
                        justifyContent={'space-between'}
                    >
                        <TypographyBodyOne>Athlete Weight (lbs)</TypographyBodyOne>
                        <TextField
                            value={weight}
                            onChange={(e) => setWeight(+e.target.value)}
                            variant="outlined"
                            color="secondary"
                            type="number"
                        />
                    </Box>
                    <Box
                        paddingY={8}
                        alignItems={'center'}
                        width={'100%'}
                        display="flex"
                        justifyContent={'space-between'}
                    >
                        <TypographyBodyOne>Athlete Height (inches)</TypographyBodyOne>
                        <TextField
                            value={height}
                            onChange={(e) => setHeight(+e.target.value)}
                            variant="outlined"
                            color="secondary"
                            type="number"
                        />
                    </Box>
                    <Box
                        paddingY={8}
                        alignItems={'center'}
                        width={'100%'}
                        display="flex"
                        justifyContent={'space-between'}
                    >
                        <div>
                            <TypographyBodyOne>Sprint Detection Velocity</TypographyBodyOne>
                            <TypographyBodyTwo>
                                Minimum velocity an athlete must run to be counted as a sprint.
                            </TypographyBodyTwo>
                        </div>
                        <FormControl variant="outlined">
                            <Select
                                value={minVelocity}
                                onChange={(event) => setMinVelocity(+event.target.value)}
                                name="min_velocity"
                                type="number"
                            >
                                <MenuItem value={2.68}>6mph</MenuItem>
                                <MenuItem value={3.57}>8mph</MenuItem>
                                <MenuItem value={4.47}>10mph</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box
                        paddingY={8}
                        alignItems={'center'}
                        width={'100%'}
                        display="flex"
                        justifyContent={'space-between'}
                    >
                        {uploadedFileName && (
                            <Box
                                style={{
                                    backgroundColor: green[100],
                                    fontWeight: 'bold',
                                }}
                            >
                                <TypographyBodyOne>
                                    Selected File: {originalFileName}
                                </TypographyBodyOne>
                            </Box>
                        )}
                        {!uploadedFileName && (
                            <TypographyBodyOne>Choose Data File To Upload</TypographyBodyOne>
                        )}
                        <>
                            <style>{gradientAnimation}</style>
                            <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    if (inputElement.current) {
                                        inputElement.current.click();
                                    }
                                }}
                                disabled={fileUploading}
                                style={{
                                    background: fileUploading
                                        ? 'linear-gradient(-45deg, #83a4d4, #b6fbff, #839d9a, #3b6978, #204051)'
                                        : 'none',
                                    backgroundSize: fileUploading ? '400% 400%' : 'none',
                                    color: fileUploading ? '#FFFFFF' : '',
                                    animation: fileUploading ? 'gradient 3s ease infinite' : 'none',
                                }}
                            >
                                {fileUploading ? 'Uploading...' : 'Choose File'}
                            </Button>
                        </>
                        <input
                            type="file"
                            accept="text/csv"
                            hidden
                            ref={inputElement}
                            onChange={(event) => {
                                if (event.target.files && event.target.files.length > 0) {
                                    uploadToS3(event.target.files[0]);
                                }
                            }}
                        />
                    </Box>
                    <Box
                        paddingY={8}
                        alignItems={'center'}
                        width={'100%'}
                        display="flex"
                        justifyContent={'space-between'}
                    >
                        <div>
                            <TypographyBodyOne>Session Date</TypographyBodyOne>
                            <TypographyBodyTwo>
                                Choose the date this activity occurred
                            </TypographyBodyTwo>
                        </div>

                        <SelectDateChip
                            onChange={(value) => {
                                setDate(value ?? DateTime.now());
                            }}
                            value={date ? date : null}
                        />
                    </Box>
                </Box>
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    padding={8}
                >
                    <Button onClick={() => onClose()}>Cancel</Button>
                    <>
                        <style>{gradientAnimation}</style>
                        <Button
                            onClick={() => save()}
                            disabled={
                                fileUploading ||
                                !uploadedFileName ||
                                !date ||
                                saving ||
                                !height ||
                                !weight
                            }
                            color="primary"
                            variant="contained"
                            style={{
                                background: saving
                                    ? 'linear-gradient(-45deg, #83a4d4, #b6fbff, #839d9a, #3b6978, #204051)'
                                    : '', // Default non-uploading state color
                                animation: saving ? 'gradient 3s ease infinite' : 'none',
                                color: saving ? '#FFFFFF' : '',
                                backgroundSize: saving ? '400% 400%' : 'none',
                            }}
                        >
                            {saving ? 'Computing...' : 'Compute Sprint Profile'}
                            <AutoGraphIcon sx={{ marginLeft: fileUploading ? 0 : 2 }} />
                        </Button>
                    </>
                </Box>
            </Box>
        </Drawer>
    );
}

export default PerformanceDataUploadDrawer;
