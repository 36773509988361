import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { store, useAppDispatch } from 'redux/store';
import { useHistory, useLocation } from 'react-router-dom';
import { TextField } from 'formik-mui';
import { authSelector, loginUser } from 'redux/reducers/auth';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LocationState, yupSchemas } from 'utils';
import { ButtonWithLoader } from 'components/Buttons';
import { useSelector } from 'react-redux';
import { getNotificationCounts, getUserData } from '../../redux/reducers/user';
import useTheme from '@mui/material/styles/useTheme';
import * as Sentry from '@sentry/react';

const LoginForm = () => {
    const theme = useTheme();

    const dispatch = useAppDispatch();

    const { push } = useHistory();
    const location = useLocation<LocationState>();

    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const toggleVisibility = () => setPasswordVisibility(!passwordVisibility);

    const { isLoading } = useSelector(authSelector);

    return (
        <Formik
            initialValues={yupSchemas.usernameAndPassword.getDefault()}
            validationSchema={yupSchemas.usernameAndPassword}
            onSubmit={async ({ username, password }, { setErrors }) => {
                const result = await dispatch(loginUser({ username, password }));
                if (loginUser.fulfilled.match(result)) {
                    if (localStorage.getItem('athleteSubscribeUrl')) {
                        const redirect = localStorage.getItem('athleteSubscribeUrl');
                        localStorage.removeItem('athleteSubscribeUrl');
                        window.location.href = redirect || '/home';
                    } else if (location.state?.referrer) {
                        push(location.state.referrer);
                    } else {
                        push('/home');
                    }
                    dispatch(getNotificationCounts());
                    await dispatch(getUserData());
                    store.subscribe(() => {
                        console.log('subscribe');
                        const state = store.getState();
                        const { isAuth } = state.auth;
                        const { userData } = state.user;

                        if (isAuth && userData) {
                            Sentry.setUser({
                                id: userData.uuid,
                                username: userData.user_name,
                                email: userData.email,
                                name: `${userData.first_name} ${userData.last_name}`,
                            });
                        } else {
                            Sentry.setUser(null);
                        }
                    });
                } else {
                    if (result.payload) {
                        // Server sent validation errors
                        if ('errors' in result.payload) {
                            setErrors(result.payload?.errors || {});
                        }
                    }
                }
            }}
        >
            {({ isValid, dirty }) => (
                <Form>
                    <Field
                        sx={{ width: '100%', marginBottom: theme.spacing(10) }}
                        component={TextField}
                        variant="filled"
                        name="username"
                        type="username"
                        label="Username"
                    />
                    <Field
                        sx={{ width: '100%', marginBottom: theme.spacing(10) }}
                        component={TextField}
                        variant="filled"
                        name="password"
                        type={passwordVisibility ? 'text' : 'password'}
                        label="Password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={toggleVisibility}
                                        onMouseDown={toggleVisibility}
                                        size="large"
                                    >
                                        {passwordVisibility ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <ButtonWithLoader
                        size="large"
                        type="submit"
                        color="primary"
                        variant="contained"
                        sx={{ width: '100%', marginBottom: theme.spacing(10) }}
                        disabled={isLoading ? true : !(isValid && dirty)}
                        loading={isLoading}
                    >
                        {isLoading ? 'Signing In' : 'Sign In'}
                    </ButtonWithLoader>
                </Form>
            )}
        </Formik>
    );
};

export default LoginForm;
