import { Box, Button, Typography } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useTheme from '@mui/material/styles/useTheme';

export interface CopyLinkProps {
    link: string;
    onCopyToClipboard: (link: string) => void;
    disabled?: boolean;
}

function CopyLink({ onCopyToClipboard, link, disabled }: CopyLinkProps): ReactElement {
    const theme = useTheme();
    const [copyText, setCopyText] = useState('Copy');
    return (
        <Box
            sx={{
                margin: theme.spacing(4, 0),
                background: theme.palette.backgroundColor.main,
                height: theme.spacing(28),
                padding: theme.spacing(0, 8),
                borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
            }}
        >
            <Typography
                noWrap
                variant="body1"
                align="left"
                color="textSecondary"
                sx={{ marginRight: theme.spacing(4), color: theme.palette.primary.main }}
            >
                {link}
            </Typography>
            <div>
                <CopyToClipboard
                    text={link}
                    onCopy={() => {
                        setCopyText('Copied!');
                        onCopyToClipboard(link);
                        setTimeout(() => {
                            setCopyText('Copy');
                        }, 1000);
                    }}
                >
                    <Button
                        disabled={disabled}
                        variant="text"
                        color="primary"
                        disableRipple
                        disableFocusRipple
                        sx={{ padding: 0, minWidth: 0 }}
                    >
                        {copyText}
                    </Button>
                </CopyToClipboard>
            </div>
        </Box>
    );
}

export { CopyLink };
