import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { debounce, isEmpty } from 'lodash';
import { Group, SearchedGroup, SearchToken, somethingWentWrong } from 'utils';
import { Close } from '@mui/icons-material';
import { getSearchToken, search } from 'api/Search';
import { AlgoliaIndex } from 'api/Search/algoliaSearch';
import { bulkCreateMembers } from '../../../api';

interface BulkMemberSelectionDialogProps {
    open: boolean;
    onClose: () => void;
    organizationUuid?: string | undefined;
    group: Group;
    memberType: { name: string; uuid: string };
    onSuccess: () => void;
}

export default function BulkMemberSelectionDialog({
    open,
    onClose,
    organizationUuid,
    group,
    memberType,
    onSuccess,
}: BulkMemberSelectionDialogProps): ReactElement {
    const theme = useTheme();
    const [loading, setLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const [bulkSearchQuery, setBulkSearchQuery] = useState<string>('');
    const [bulkIndividuals, setBulkIndividuals] = useState<SearchedGroup[]>([]);
    const [bulkSelectedIndividuals, setBulkSelectedIndividuals] = useState<Set<string>>(new Set());
    const [bulkSearchToken, setBulkSearchToken] = useState<SearchToken | null>(null);

    const searchAlgolia = useCallback(
        (query: string) => {
            if (bulkSearchToken) {
                setLoading(true);
                search(AlgoliaIndex.Individual, {
                    searchQuery: query,
                    searchToken: bulkSearchToken,
                    filters: organizationUuid ? [`organizationId:${organizationUuid}`] : [],
                })
                    .then((response) => {
                        if (response.data) {
                            setBulkIndividuals(
                                response.data.hits.map((hit) => ({
                                    ...hit,
                                    groupIds: hit.groupIds ?? [],
                                })),
                            );
                        }
                    })
                    .catch((error) => {
                        somethingWentWrong(error);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        },
        [bulkSearchToken, organizationUuid],
    );

    useEffect(() => {
        if (bulkSearchToken) {
            searchAlgolia(bulkSearchQuery);
        }
    }, [bulkSearchQuery, searchAlgolia, bulkSearchToken]);

    const debouncedSearchQuery = React.useRef(
        debounce((value: string) => {
            setBulkSearchQuery(value);
        }, 100),
    ).current;

    const handleIndividualToggle = (id: string) => {
        setBulkSelectedIndividuals((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    };

    const handleBulkAdd = async () => {
        setSubmitting(true);
        try {
            const selectedMembers = bulkIndividuals
                .filter((individual) => bulkSelectedIndividuals.has(individual.id))
                .map((individual) => ({
                    first_name: individual.name.split(' ')[0],
                    last_name: individual.name.split(' ').slice(1).join(' '),
                    attributes: [],
                    member_type: memberType.uuid,
                    individual: individual.id,
                }));

            await bulkCreateMembers(group.uuid, selectedMembers);
            clearResults();
            onSuccess();
            onClose();
        } catch (error) {
            console.error(error);
            somethingWentWrong('An error occurred while adding members');
        } finally {
            setSubmitting(false);
        }
    };

    const clearResults = () => {
        setBulkSearchQuery('');
        setBulkIndividuals([]);
        setBulkSelectedIndividuals(new Set());
        setLoading(false);
    };

    useEffect(() => {
        if (open && !bulkSearchToken) {
            setLoading(true);
            getSearchToken()
                .then((response) => {
                    setBulkSearchToken(response.data as SearchToken);
                })
                .catch(() => somethingWentWrong())
                .finally(() => setLoading(false));
        }
    }, [open, bulkSearchToken]);

    // Clear everything when dialog is closed
    useEffect(() => {
        if (!open) {
            clearResults();
            setBulkSearchToken(null);
        }
    }, [open]);

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={onClose}
            aria-labelledby="bulk-member-selection-dialog"
        >
            <DialogTitle>
                <Typography variant="h6">Add Multiple Members</Typography>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        clearResults();
                        onClose();
                    }}
                    sx={{
                        position: 'absolute',
                        right: theme.spacing(4),
                        top: theme.spacing(4),
                        color: theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    minHeight: theme.spacing(300),
                    [theme.breakpoints.up('md')]: {
                        minWidth: 600,
                    },
                }}
            >
                <TextField
                    fullWidth
                    placeholder="Search members..."
                    onChange={(e) => debouncedSearchQuery(e.target.value)}
                    value={bulkSearchQuery}
                />

                {bulkSearchToken ? (
                    <Box
                        textAlign="center"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        style={{ gap: 10 }}
                    >
                        {loading && (
                            <Box
                                mt={20}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                style={{ gap: 10 }}
                            >
                                <CircularProgress />
                                <Typography variant="caption">
                                    Finding matching members...
                                </Typography>
                            </Box>
                        )}

                        {bulkIndividuals.length > 0 && !loading && (
                            <div style={{ marginTop: theme.spacing(5), width: '100%' }}>
                                <Typography variant="caption" align="center" color="textSecondary">
                                    {bulkSelectedIndividuals.size} selected of{' '}
                                    {bulkIndividuals.length} results
                                </Typography>
                                <List
                                    dense
                                    sx={{
                                        width: '100%',
                                        maxHeight: theme.spacing(200),
                                        overflowY: 'auto',
                                        bgcolor: 'background.paper',
                                        '& .MuiListItem-root': {
                                            borderBottom: '1px solid',
                                            borderColor: 'divider',
                                            py: 1,
                                        },
                                    }}
                                >
                                    {bulkIndividuals.map((individual) => {
                                        const isInGroup =
                                            individual.groupIds.indexOf(group.uuid) > -1;
                                        return (
                                            <ListItem
                                                key={individual.id}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flex: 1,
                                                    }}
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            alt={individual.name}
                                                            src={individual.objectAvatarPhoto || ''}
                                                            variant="rounded"
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText>
                                                        <Typography variant="body2">
                                                            {individual.name}
                                                        </Typography>
                                                        {!isEmpty(individual.organizationName) && (
                                                            <Typography
                                                                variant="caption"
                                                                color="textSecondary"
                                                            >
                                                                {individual.organizationName}
                                                            </Typography>
                                                        )}
                                                    </ListItemText>
                                                </Box>
                                                {!isInGroup ? (
                                                    <Checkbox
                                                        checked={bulkSelectedIndividuals.has(
                                                            individual.id,
                                                        )}
                                                        onChange={() =>
                                                            handleIndividualToggle(individual.id)
                                                        }
                                                        color="primary"
                                                    />
                                                ) : (
                                                    <Typography
                                                        variant="caption"
                                                        color="textSecondary"
                                                    >
                                                        Already a member
                                                    </Typography>
                                                )}
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </div>
                        )}

                        {!isEmpty(bulkSearchQuery) && bulkIndividuals.length === 0 && !loading && (
                            <>
                                <Box fontStyle="italic" pt={20} pb={20}>
                                    <Typography align="center" variant="body2">
                                        No matching members found
                                    </Typography>
                                </Box>
                            </>
                        )}
                    </Box>
                ) : (
                    <Box pt={20} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </DialogContent>
            <DialogActions sx={{ padding: theme.spacing(4) }}>
                <Button onClick={onClose} disabled={submitting}>
                    Cancel
                </Button>
                <Button
                    onClick={handleBulkAdd}
                    variant="contained"
                    color="primary"
                    disabled={bulkSelectedIndividuals.size === 0 || submitting}
                >
                    {submitting ? (
                        <CircularProgress size={24} />
                    ) : (
                        `Add ${bulkSelectedIndividuals.size} Member${
                            bulkSelectedIndividuals.size !== 1 ? 's' : ''
                        }`
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
